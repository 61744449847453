import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Box, Typography, useMediaQuery, useTheme, Button, Container, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import ChangePassword from '../ChangePassword/changePassword';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/logo.png";
import hirio_Logo from "../../assets/images/HirioBlue.png";
import { OnBoardingApi, UploadImageApi } from "../../apis";
import UploadImage from '../GlobalComponent/UploadImage';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import JobContext from '../../context/JobContext';

export default function Dashboard(props) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedNavItem, setSelectedNavItem] = useState('');
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const location = useLocation(); // Use useLocation hook here
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const {addNewMessage,newMessage} = React.useContext(JobContext);
    let user;

    try {
        const userString = localStorage.getItem("user");

        user = JSON.parse(userString);
    } catch (error) {
        console.error("Error parsing user from localStorage:", error);
    }



    const isEmployee = user && user.role.name === "employee";
    const isRecruiter = user && user.role.name === "recruiter";

    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);
    const [scrolled, setScrolled] = useState(false);

    const fetchOnBoardedUserData = async () => {
        try {
            const res = await OnBoardingApi.getOnBoarding(); // Adjust API call as needed

            setUserData(res?.data?.profile);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching on-boarded user data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOnBoardedUserData()

    }, []);
    useEffect(() => {

        const pathname = location.pathname;

        if (isRecruiter) {

            setSelectedNavItem(getSelectedMenuItem(pathname));
        }
        else if (isEmployee) {
            setSelectedNavItem(getSelectedEmployeeMenuItem(pathname));
        }
    }, [location.pathname]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
        window.location.reload();
    };

    const getSelectedMenuItem = (pathname) => {
        switch (pathname) {
            case '/v1/recruiter/home':
                return 'Home';
            // case '/v1/recruiter/best-matches':
            //     return 'Best Matches';
            case '/v1/recruiter/job-offers':
                return 'Job Offers';
            case '/v1/recruiter/my-job-posts':
                return 'My Job Posts';
            case '/v1/recruiter/chat':
                return 'Chat';
            default:
                return '';
        }
    };
    const getSelectedEmployeeMenuItem = (pathname) => {
        switch (pathname) {
            case '/v1/employee/home':
                return 'Home';
            case '/v1/employee/job-offers':
                return 'Job Offers';
            case '/v1/employee/resume':
                return 'Resume';
            case '/v1/employee/chat':
                return 'Chat';
            default:
                return '';
        }
    };
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleRecruiterNavigationItemClick = (text) => {
        setSelectedNavItem(text);
        let path = '';
      
        switch (text) {
          case 'Home':
            path = '/v1/recruiter/home';
            break;
          case 'Job Offers':
            path = '/v1/recruiter/job-offers';
            break;
          case 'My Job Posts':
            path = '/v1/recruiter/my-job-posts';
            break;
          case 'Chat':
            path = '/v1/recruiter/chat';
            break;
          default:
            console.error(`No route defined for ${text}`);
            return; // Exit if no valid route is found
        }
      
        navigate(path);
      };
    const handleEmployeeNavigationItemClick = (text) => {
        setSelectedNavItem(text);
        let path = '';
      
        switch (text) {
          case 'Home':
            path = '/v1/employee/home';
            break;
          case 'Job Offers':
            path = '/v1/employee/job-offers';
            break;
          case 'Resume':
            path = '/v1/employee/resume';
            break;
          case 'Chat':
            path = '/v1/employee/chat';
            break;
          default:
            console.error(`No route defined for ${text}`);
            return; // Exit if no valid route is found
        }
      
        navigate(path);
      };
    const handleOpenChangePassword = () => {
        setChangePasswordOpen(true);
        handleClose(); // Close the menu when opening change password dialog
    };

    const handleCloseChangePassword = () => {
        setChangePasswordOpen(false);
    };
    const handleScroll = () => {
        const currentScrollPos = window.scrollY;


        if (currentScrollPos > 100) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }


        if (isTablet) {
            if (currentScrollPos > prevScrollPos) {
                setVisible(false);
            } else if (currentScrollPos < prevScrollPos) {
                setVisible(false);
            }

            if (currentScrollPos <= 100) {
                setVisible(true); // If at the top of the page, show the navbar
            }
        } else { // Desktop mode logic
            if (currentScrollPos > 100 && currentScrollPos > prevScrollPos) {
                setVisible(true); // If scrolling down on desktop, show the navbar
            } else if (currentScrollPos > 100 && currentScrollPos < prevScrollPos) {
                setVisible(true); // If scrolling up on desktop, show the navbar
            } else if (currentScrollPos <= 100) {
                setVisible(true); // If at the top of the page, show the navbar
            }
        }

        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);



    const drawerWidth = 240;
    const drawer = (
        <Drawer
            variant="temporary"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerClose}
            ModalProps={{ keepMounted: true }} // Better open performance on mobile
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', bgcolor: 'primary.main', color: "text.secondary" },
            }}
        >
            {isRecruiter && <Link to="/v1/recruiter/home" style={{ textDecoration: 'none' }}>
                <Box sx={{ marginRight: "2%", m: 2 }}>
                    <img src={Logo} alt="logo" style={{ width: "100px" }} />
                </Box>
            </Link>}

            {isEmployee &&
                <Link to="/v1/employee/home" style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginRight: "2%", m: 2 }}>
                        <img src={Logo} alt="logo" style={{ width: "100px" }} />
                    </Box>
                </Link>}
            <Divider />
            {isRecruiter && <List>
                {['Home', 'Job Offers', 'My Job Posts', 'Chat'].map((text) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton selected={selectedNavItem === text} onClick={() => handleRecruiterNavigationItemClick(text)}>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>}

            {isEmployee && <List>
                {['Home', 'Job Offers', 'Resume', 'Chat'].map((text) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton selected={selectedNavItem === text} onClick={() => handleEmployeeNavigationItemClick(text)}>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>}

        </Drawer>
    );
    const recruiterNavigationList = (
        <Box sx={{ display: 'flex' }}>
          {['Home', 'Job Offers', 'My Job Posts', 'Chat'].map((text) => (
            <ListItemButton
              disableRipple
              key={text}
              selected={selectedNavItem === text}
              onClick={() => handleRecruiterNavigationItemClick(text)}
              sx={{
                ml: 1,
                color: selectedNavItem === text ? (scrolled ? 'secondary.main' : 'secondary.main') : (scrolled ? 'white' : 'white'),
                '&:hover': {
                  cursor: selectedNavItem === text ? 'auto' : 'pointer',
                },
                '&.Mui-selected': {
                  backgroundColor: scrolled ? 'transparent' : 'transparent',
                },
              }}
            >
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {text}
                    {(text === 'Chat' && newMessage) && (
                      <FiberManualRecordIcon
                        sx={{ 
                          fontSize: '0.8rem', 
                          color: 'secondary.main',
                          ml: 1,
                        }} 
                      />
                    )}
                  </Box>
                }
              />
            </ListItemButton>
          ))}
        </Box>
      );
      

    const employeeNavigationList = (
        <Box sx={{ display: 'flex' }}>
          {['Home', 'Job Offers', 'Resume', 'Chat'].map((text) => (
            <ListItemButton
              disableRipple
              key={text}
              selected={selectedNavItem === text}
              onClick={() => handleEmployeeNavigationItemClick(text)}
              sx={{
                ml: 1,
                color: selectedNavItem === text ? (scrolled ? 'secondary.main' : 'secondary.main') : (scrolled ? 'white' : 'white'),
                '&:hover': {
                  cursor: selectedNavItem === text ? 'auto' : 'pointer',
                },
                '&.Mui-selected': {
                  backgroundColor: scrolled ? 'transparent' : 'transparent',
                },
              }}
            >
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {text}
                    {(text === 'Chat' && newMessage) && (
                      <FiberManualRecordIcon
                        sx={{ 
                          fontSize: '0.8rem', 
                          color: 'secondary.main',
                          ml: 1,
                        }} 
                      />
                    )}
                  </Box>
                }
              />
            </ListItemButton>
          ))}
        </Box>
      );
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" sx={{
                top: visible ? 0 : -100,
                backgroundColor: scrolled ? 'primary.main' : 'primary.main',
                color: scrolled ? theme.palette.primary.main : '#FFF'
            }}>
                <Toolbar sx={{
                    position: 'relative',
                }}>
                    {isTablet && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerOpen}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon sx={{ color: 'secondary.main' }} />
                        </IconButton>
                    )}
                    {!isTablet && (

                        <Link to={"/"} style={{ textDecoration: 'none' }}>
                            <Box
                                sx={{ marginRight: 5, my: 2 }}>
                                <img src={scrolled ? Logo : Logo} alt="logo" style={{ width: "100px" }} />
                            </Box>
                        </Link>

                    )}
                    {!isTablet && (isEmployee ? employeeNavigationList : recruiterNavigationList)}
                    <Box sx={{ ml: 'auto', }}>
                        {isRecruiter &&
                            <Button
                                type="submit"
                                variant="outlined"
                                onClick={() => navigate('/v1/recruiter/create-job-post/step1')}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    borderColor: 'primary.main',
                                    color: 'primary.main',
                                    backgroundColor: 'secondary.main',
                                    '&:hover': {
                                        backgroundColor: 'secondary.main',
                                        borderColor: 'primary.dark', // Adjust as needed for hover effect
                                        color: 'primary.dark' // Adjust as needed for hover effect
                                    },
                                }}
                            >
                                <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                                    Create Job Post
                                </Typography>
                            </Button>
                        }

                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            sx={{

                                '&:hover': {
                                    backgroundColor: 'primary.main',
                                },
                                width: '50px',
                            }}
                        >

                            {userData && userData.profile_photo_url ? (
                                <Avatar
                                    src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData.profile_photo_url}`}
                                    alt={userData.profile_photo_url}
                                    sx={{ width: 35, height: 35 }}
                                />
                            ) : (
                                <AccountCircle
                                    sx={{
                                        color: 'gray',
                                        width: 40,
                                        height: 40,
                                    }}
                                />
                            )}


                        </IconButton>

                    </Box>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        sx={{
                            marginTop: "50px"
                        }}
                    >
                        {isRecruiter &&
                            <Box>
                                <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px",textAlign:"center" }}>
                                   {userData?.full_name || ""}
                                </Typography>
                                <Divider/>
                                <MenuItem onClick={() => { handleClose(); navigate('/v1/recruiter/my-profile'); }}>View Profile</MenuItem>
                            </Box>

                        }
                        {isEmployee &&
                            <Box>
                                <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px",textAlign:"center" }}>
                                {userData?.full_name || ""}
                                </Typography>
                                <Divider/>
                                <MenuItem onClick={() => { handleClose(); navigate('/v1/employee/my-profile'); }}>View Profile</MenuItem>
                            </Box>

                        }
                        <MenuItem onClick={handleOpenChangePassword}>Change Password</MenuItem>
                        <MenuItem
                            //  onClick={() => {
                            //     handleClose();
                            //     localStorage.clear();
                            //     navigate('/');
                            // }}
                            onClick={handleLogout}
                        >Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            {isTablet && drawer}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <Container>
                    {props.children}
                </Container>
            </Box>
            <Dialog open={changePasswordOpen} onClose={handleCloseChangePassword}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px", ml: 2 }}>
                        Change password
                    </Typography>

                    <IconButton onClick={handleCloseChangePassword}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ChangePassword />
                </DialogContent>

            </Dialog>
        </Box>
    );
}