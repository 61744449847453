import React, { useEffect, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';

const CustomAutocomplete = ({ formik }) => {
    const { setFieldValue } = formik;
    const autocompleteRef = useRef(null);

    const handlePlaceSelected = (place) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setFieldValue('company_profile.company_location', place.formatted_address);
        setFieldValue('company_profile.latitude', lat);
        setFieldValue('company_profile.longitude', lng);
    };

    useEffect(() => {
        if (autocompleteRef.current) {
            const inputElement = autocompleteRef.current.querySelector('input');
            if (inputElement) {
                inputElement.style.setProperty('color', 'gray'); // Change input text color
            }
        }
    }, []);

    return (
        <Autocomplete
            ref={autocompleteRef}
            style={{
                height: '55px',
                width: '100%',
                backgroundColor: '#fff',
                borderRadius: '4px',
                fontSize: '16px',
                color: 'black',
                border: '1px solid lightgrey',
                paddingLeft: '10px',
                zindex: '1500 !important',
                
            }}
          
            apiKey={process.env.REACT_APP_GOOGLE_PLACE_API_KEY} // Replace with your API key
            onPlaceSelected={handlePlaceSelected}
        />
    );
};

export default CustomAutocomplete;
