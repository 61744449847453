import React from 'react';
import NavBar from '../../../../components/Global/NavBar';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Chat from '../../../../components/Chat/chat';
import Footer from '../../../../components/LandingPage/Footer';


const Chats = () => {



    return (
       <>
        <Grid container justifyContent="center" sx={{ backgroundColor: '#F5F5F5', minHeight: '100vh' }}>
            <Grid item xs={12} md={12} lg={12} xl={12} >
                <NavBar>
                    <Chat />
                </NavBar>
            </Grid>
        </Grid>
        <Footer/>
       </>
    );
}

export default Chats;
