import React from "react";
import NavBar from "../../components/LandingPage/navBar";
import Banner from "../../components/LandingPage/Banner";
import HowItWorks from "../../components/LandingPage/howItWorks/howItWorks";
import DreamJobSection from "../../components/LandingPage/DreamJobSection";
import CompanySection from "../../components/LandingPage/CompanySection";
import TopTalentSection from "../../components/LandingPage/TopTalentSection";
import TestimonialSection from "../../components/LandingPage/TestimonialSection";
import Footer from "../../components/LandingPage/Footer";
import Steps from "../../components/LandingPage/Steps";
import FeatureSection from "../../components/LandingPage/FeatureSection";
import MatchSection from "../../components/LandingPage/MatchSection";
import WhyHirio from "../../components/LandingPage/WhyHirio";
import SobreLaCompania from "../../components/LandingPage/SobreLaCompania";
import DesdeEspana from "../../components/LandingPage/DesdeEspana";
import DeQueSurgeElProyecto from "../../components/LandingPage/DeQueSurgeElProyecto";
import LoQueComentan from "../../components/LandingPage/LoQueComentan";
import { Container } from "@mui/material";

export default function LandingPage() {

    return (
        <>

            <section>
                
                {/* <NavBar />
                <Banner /> */}
                <NavBar />
                <Banner />
            </section>
            <Container
                maxWidth="1600"
                sx={{
                    maxWidth: "1600px"

                }}
                style={{  padding: 0 }}
            >
                <section id="how-it-works">
                    <Steps />
                    <FeatureSection />
                    <MatchSection/>
                    <WhyHirio />
                    <SobreLaCompania />
                    <DesdeEspana/>
                    <DeQueSurgeElProyecto />
                    <LoQueComentan />
                    {/* <HowItWorks /> */}
                </section>
                {/* <DreamJobSection />
                <CompanySection />
                <TopTalentSection /> */}
                <section id="testimonials">
                    {/* <TestimonialSection /> */}
                </section>

            </Container>
            <Footer />
        </>


    );
}

