import React from 'react';

const CurrencyFormatter = ({ value, isSuffix = true }) => {
    const toGermanCurrency = (number, isSuffix) => {
        // Remove commas and convert to number
        if (typeof number === 'string') {
            number = number.replace(/,/g, '');
        }
        let num = Number(number);

        // Ensure the value is a valid number before formatting
        if (isNaN(num) || num === null || num === undefined) {
            return 'N/A';
        }

        num = num.toLocaleString("de-DE", { minimumFractionDigits: 2 });
        if (isSuffix) return `${num} €`;
        return num;
    };

    return <>{toGermanCurrency(value, isSuffix)}</>;
};

export default CurrencyFormatter;
