import React from 'react';
import { Box } from '@mui/material';

const UploadImage = ({ imageUrl, altText }) => {
    return (
        
        <Box
            sx={{
                width:{xs:"100%",sm:80,md:110} ,
                height: {xs:"100%",sm:80,md:110} ,
                border: '1px black',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '15px',mt:1.9,
                backgroundColor: "lightgray"
            }}
        >
            {imageUrl && (
                <img
                    src={imageUrl}
                    alt={altText}
                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px'  }}
                    
                />
            )}
        </Box>
    );
};

export default UploadImage;
