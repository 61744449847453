import axios from "../axiosConfig";

class Routes {

    createPost  = (val) => {
        let data = axios.post("/post-feed/create", val)
        return data;
    };
    // updateJobPost  = (jobPostId, val) => {
    //     let data = axios.post(`/job-post/update-job-post/${jobPostId}`,val)
    //     return data;
    // };
    // deleteJobPost  = (jobPostId) => {
    //     const data = axios.delete(`/job-post/delete-job-post/${jobPostId}`)
    //     console.log('Deleting job post with URL:', data); // Add this line for debugging
    //     return data
       
    // };
    getPost = () => {
        let data = axios.get("/post-feed/get-all")
        return data;
    };

 
    // getJobPostById=(id)=>{
    //     let data = axios.get("/job-post/get?id="+id);
    //     return data;
    //   }
}

export default new Routes();