import React from 'react';
import { Grid, Typography, Box, InputLabel, FormControl, Select, MenuItem, Container } from '@mui/material';

const ExperienceForm = ({
    formik,
    name,
    specialties,
    profession,
    experienceYears,
    selectedCategories,
    experienceList,
    handleCheckboxChange,
    handleSubcategoryChange,

}) => {
    console.log("sp",specialties);

    return (
       
      <>
        <FormControl fullWidth>
            <Select
                name={name}
                value={profession}
                onChange={handleCheckboxChange}
                displayEmpty
                renderValue={(selected) => {
                    if (!selected) {
                        return <span style={{ color: '#aaa' }}>Select Profession</span>;
                    }
                    return selected;
                }}
            >
                <MenuItem value="" disabled>
                    <em>Select Profession</em>
                </MenuItem>
                {specialties.map((specialty) => (
                    <MenuItem key={specialty} value={specialty}>
                        {specialty}
                    </MenuItem>
                ))}
            </Select>
            {formik.touched.jobDetails?.specialties && formik.errors.jobDetails?.specialties && (
                <Typography variant="body2" color="error">
                    {formik.errors.jobDetails.specialties}
                </Typography>
            )}
        </FormControl>
        
        {Object.keys(selectedCategories).map((category) => (
            <React.Fragment key={category}>
                <Grid item xs={12}>
                    <InputLabel
                        sx={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "text.primary",
                            marginTop: "10px",
                            mb: 2,
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            wordBreak: "break-word",
                        }}
                    >
                        How many years of experience do you have in the {category} field?
                    </InputLabel>
                </Grid>

                {experienceYears[category] && experienceYears[category].length > 0
                    ? (
                        <Grid container spacing={2}>
                            {Object.keys(experienceYears[category][0]).map((subcategory) => (
                                <Grid item xs={12} sm={12} key={subcategory}>
                                    <InputLabel sx={{ color: 'black' }}>
                                        {subcategory}
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            name={`experienceYears.${category}[0].${subcategory}`}
                                            value={experienceYears[category][0]?.[subcategory] || ''}
                                            onChange={handleSubcategoryChange(category, subcategory)}
                                            displayEmpty
                                            renderValue={(selected) => {
                                                return selected || <span style={{ color: '#aaa' }}>Select Total Experience</span>;
                                            }}
                                            aria-label={`Experience in ${subcategory}`}
                                        >
                                            <MenuItem value="" disabled>
                                                <em>Select Total Experience</em>
                                            </MenuItem>
                                            {experienceList.map((experience) => (
                                                <MenuItem key={experience} value={experience}>
                                                    {experience} years
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ))}
                        </Grid>
                    )
                    : (
                        <Typography variant="body2" color="error">
                            No subcategories found for {category}.
                        </Typography>
                    )}
            </React.Fragment>
        ))}
  
          
        </>
    );
};

export default ExperienceForm;
