// src/context/JobProvider.js

import React, { useState, useEffect } from 'react';
import JobContext from './JobContext';
import { JobPostApi } from '../../src/apis';

const JobProvider = ({ children }) => {
    const [jobDataArray, setJobDataArray] = useState([]);
    const [pendingJobData, setPendingJobData] = useState([]);
    const [acceptedJobData, setAcceptedJobData] = useState([]);
    const [rejectedJobData, setRejectedJobData,] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newMessage,setNewMessage] = useState(false);
    const removeJobPost = (id) => {
        setJobDataArray(prevData => prevData.filter(job => job.id !== id));
    };

    const addNewMessage = (data) => {
        setNewMessage(data);
    }


    return (
        <JobContext.Provider value={{ allJobs: jobDataArray, jobDataArray, setJobDataArray, pendingJobData, setPendingJobData,acceptedJobData,setAcceptedJobData,rejectedJobData, setRejectedJobData, loading, setLoading, removeJobPost,newMessage ,addNewMessage}}>
            {children}
        </JobContext.Provider>
    );
};

export default JobProvider;
