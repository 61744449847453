import axios from "../axiosConfig";

class Routes {

    createJobPost  = (val) => {
        let data = axios.post("/job-post/create-job-post", val)
        return data;
    };
    updateJobPost  = (jobPostId, val) => {
        let data = axios.post(`/job-post/update-job-post/${jobPostId}`,val)
        return data;
    };
    deleteJobPost  = (jobPostId) => {
        const data = axios.delete(`/job-post/delete-job-post/${jobPostId}`)
        return data
       
    };
    getJobPost = () => {
        let data = axios.get("/job-post/get")
        return data;
    };
    getAllJobPost = () => {
        let data = axios.get("/job-post/getAll")
        return data;
    };
    getJobPostById=(id)=>{
        let data = axios.get(`/job-post/get/${id}`);
        return data;
      
    };
    getSuitableUsers=(id)=>{
        let data = axios.get(`/job-post/get-suitable-users/${id}`);
        return data;
      
    };
    getJobs=(id)=>{
        let data = axios.get(`/job-post/get-suitable-users/${id}`);
        return data;
      
    };
       // get-applied-jobs
    getUserJobsOffer = () =>{
        let data = axios.get("/job-post/get-user-jobs-offer")
        return data;
    }

}

export default new Routes();