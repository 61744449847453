import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RecruiterRoutes from "../router/RecruiterRoutes";
import EmployeeRoutes from "../router/EmployeeRoutes";

function getUserRole() {
  let user = null;

  try {
    const userString = localStorage.getItem("user");
    user = JSON.parse(userString);
  } catch (error) {
    console.error("Error parsing user from localStorage:", error);
  }

  
  const isEmployee = user && user.role && user.role.name === "employee";
  const isRecruiter = user && user.role && user.role.name === "recruiter";

  return { isEmployee, isRecruiter };
}

function Main() {
  const navigate = useNavigate();
  const [isEmployee, setIsEmployee] = useState(false);
  const [isRecruiter, setIsRecruiter] = useState(false);

  useEffect(() => {
    const { isEmployee, isRecruiter } = getUserRole();
    setIsEmployee(isEmployee);
    setIsRecruiter(isRecruiter);
    
    if (!isEmployee && !isRecruiter) {
      navigate('/login'); 
    }
  }, [navigate]);

  return (
    <>
      {isEmployee && <EmployeeRoutes />}
      {isRecruiter && <RecruiterRoutes />}
    </>
  );
}

export default Main;
