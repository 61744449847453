import React from 'react';
import { Container, Box, Typography, TextField, Button, Divider, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthApi } from '../../apis';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = (field) => {
    switch (field) {
      case 'old_password':
        setShowOldPassword(!showOldPassword);
        break;
      case 'password':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirm_password':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    const { old_password, password, confirm_password } = values;

    if (password !== confirm_password) {
      setErrors({ confirm_password: 'New password and confirm password do not match' });
      return;
    }

    try {
      const response = await AuthApi.UpdatePassword({
        old_password,
        password,
        confirm_password
      });

      await new Promise(resolve => setTimeout(resolve, 1000));
      window.location.reload();
      // resetForm();
    } catch (error) {
      console.error('Error updating password:', error);
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Formik
        initialValues={{
          old_password: '',
          password: '',
          confirm_password: ''
        }}
        validationSchema={Yup.object().shape({
          old_password: Yup.string().required('Old Password is required'),
          password: Yup.string().required('New Password is required').min(8, 'Password must be at least 8 characters'),
          confirm_password: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
        })}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Divider sx={{ width: '100%', color: 'text.secondary', mb: 1 }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                  Old Password *
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  margin="normal"
                  name="old_password"
                  placeholder="Enter old password"
                  type={showOldPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  InputLabelProps={{ style: { fontSize: '14px' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickShowPassword('old_password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ p: '6px' }}
                        >
                          {showOldPassword ? <VisibilityOff sx={{ fontSize: '18px' }} /> : <Visibility sx={{ fontSize: '18px' }} />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {errors.old_password && touched.old_password && (
                  <Typography variant="body2" color="error">
                    {errors.old_password}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                  New Password *
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  margin="normal"
                  name="password"
                  placeholder="Enter new password"
                  type={showNewPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  InputLabelProps={{ style: { fontSize: '14px' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickShowPassword('password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ p: '6px' }}
                        >
                          {showNewPassword ? <VisibilityOff sx={{ fontSize: '18px' }} /> : <Visibility sx={{ fontSize: '18px' }} />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {errors.password && touched.password && (
                  <Typography variant="body2" color="error">
                    {errors.password}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                  Repeat Password *
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  margin="normal"
                  name="confirm_password"
                  placeholder="Repeat new password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  InputLabelProps={{ style: { fontSize: '14px' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickShowPassword('confirm_password')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ p: '6px' }}
                        >
                          {showConfirmPassword ? <VisibilityOff sx={{ fontSize: '18px' }} /> : <Visibility sx={{ fontSize: '18px' }} />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {errors.confirm_password && touched.confirm_password && (
                  <Typography variant="body2" color="error">
                    {errors.confirm_password}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'secondary.main'
                  }
                }}
              >
                <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none',color:"primary.main" }}>
                  Update
                </Typography>
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ChangePassword;
