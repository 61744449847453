import React from 'react';
import { Typography, Paper, Grid, Button, Link ,Divider} from '@mui/material';
import { Facebook, Twitter, LinkedIn } from '@mui/icons-material';



const JobListing = () => {
  // Define job details object with specific data
  const jobDetails = {
    publishedOn: '29 Oct 2023',
    vacancy: 1,
    jobType: 'Full time',
    experience: 'At least 5 Year(s)',
    jobLocation: 'Madrid',
    category:'Engineer',
    gender:'Both',
    salary: '$1K - $3K',
    applicationDeadline: '15 Nov 2023'
  };
  const SocialButton = ({ icon, text }) => (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <div style={{ width: 40, height: 40, borderRadius: '50%', backgroundColor: '#1DA1F2', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {icon} {/* Render the icon */}
        </div>
      </Grid>
      <Grid item>
        <Typography variant="body1">{text}</Typography>
      </Grid>
    </Grid>
  );
  return (
    <>
      <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#F0F6FE',marginTop:"10px" }}>
        <Typography variant="h6" gutterBottom sx={{fontWeight:"bold"}}>
          Job summary
        </Typography>
        {/* Render specific job details */}
        <Typography variant="body1">
  <strong>Published on:</strong> {jobDetails.publishedOn}<br />
  <strong>Vacancy:</strong> {jobDetails.vacancy}<br />
  <strong>Job type:</strong> {jobDetails.jobType}<br />
  <strong>Experience:</strong> {jobDetails.experience}<br />
  <strong>Job location:</strong> {jobDetails.jobLocation}<br />
  <strong>Category:</strong> {jobDetails.category}<br />
  <strong>Gender:</strong> {jobDetails.gender}<br />
  <strong>Salary:</strong> {jobDetails.salary}<br />
  <strong>Application deadline:</strong> {jobDetails.applicationDeadline}<br />
</Typography>
<Divider sx={{ width: '100%', color: 'text.secondary', mt: 3 }} />
        <Typography variant="h6" gutterBottom style={{ marginTop: '20px',fontWeight:"bold" }}>
          Job location
        </Typography>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.290996191002!2d-0.007137684229519892!3d51.12867657957772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875f02c6a2c10eb%3A0x870b0ec9d0d7ea3!2sRoyal%20Mail!5e0!3m2!1sen!2sus!4v1649693249159"
          width="600"
          height="450"
          style={{ border: 0, width: '100%', height: '300px' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <Divider sx={{ width: '100%', color: 'text.secondary', mt: 3 }} />
        <Typography variant="h6" gutterBottom style={{ marginTop: '20px',fontWeight:"bold" }}>
          Share this job
        </Typography>
        <Grid container spacing={1}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Button
              variant="standard"
              color="primary"
              sx={{
                textTransform: 'none',
                width: 'fit-content',
                paddingLeft: '14px',
                backgroundColor: '#F0F6FE',
                color: 'black',
              }}
            >
              <SocialButton icon={<Facebook style={{ color: '#fff' , mr:2}} />} text="Facebook" />
            </Button>
          </Grid>
    </Grid>
    <Grid item xs={12}>
            <Button
              variant="standard"
              color="primary"
              
              sx={{
                textTransform: 'none',
                width: 'fit-content',
                padding: '8px',
                backgroundColor: '#F0F6FE',
                color: 'black',
              }}
            >
              <SocialButton icon={<Twitter style={{ color: '#fff' }} />} text="Twitter" />
            </Button>
          </Grid>
 <Grid item xs={12}>
            <Button
              variant="standard"
              color="primary"
             
              sx={{
                textTransform: 'none',
                width: 'fit-content',
                padding: '8px',
                backgroundColor: '#F0F6FE',
                color: 'black',
              }}
            >
              <SocialButton icon={<LinkedIn style={{ color: '#fff' }} />} text="LinkedIn" />
            </Button>
          </Grid>
</Grid>

      </Paper>
    </>
  );
};

export default JobListing;
