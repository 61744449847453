import React from "react";
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader({ loading }) {
  return (
    <Box sx={{ background: "#FCFCFC", minHeight: "100vh" }}>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: '#fff',
        }}
        open={loading}
      >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
          <CircularProgress color="inherit" />
          {/* <p>{loader.msg}</p> */}
        </div>
      </Backdrop>
      {/* <NavBar /> */}
      {/* <Footer/> */}
    </Box>
  );
}
