import axios from "../axiosConfig";

class Routes {

    createMessage= (val) => {
        let data = axios.post("/message/send-message", val);
        return data;
    };
    createConversation= (val) => {
        let data = axios.post("/message/conversation", val);
        return data;
    };
    getUsersWithConversations = () => {
        let data = axios.get("/message/users-with-conversations")
        return data;
    };
    // getUsersWithConversations = (id) => {
    //     let data = axios.get(`/message/users-with-conversations/${id}`)
    //     return data;
    // };
    getAllMessages = (id) => {
        let data = axios.get(`/message/get-messages/${id}`);
        return data;
    };
}



export default new Routes();