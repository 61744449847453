import React from "react";
import { Box, Button,Container,GlobalStyles,Grid } from "@mui/material";
import Preferences from "../../Employee/Onboarding/preferences/preferences";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from 'dayjs';

const Index = () => {

  const validationSchema = yup.object({
    preferences: yup.object({
      professional_area: yup.string().required("Professional area is required"),
      total_years_experience: yup.string().required("Total Years Experience is required"),
      job_search_status: yup.string().required("Job search status is required"),

    }),
  });


  const formik = useFormik({
    initialValues: {
      preferences: {
        professional_area: "",
        total_years_experience: "",
        top_specialities:"",
       experienceYears : {
          PublicAdministration:
          [
               {
              EuropeanAdministration: "",
              AutonomousAdministration: "",
              StateAdministration: "",
              LocalAdministration: "",
              SecurityForces: "",
              PublicEnterprises: ""
          },
      ],
          BusinessAdministration: [{
              Administration: "",
              Auditing: "",
              Consulting: "",
              Management: "",
              BillingCollectionsAndPayments: "",
              FinanceAndAccounting: "",
              NegotiationAndContracting: "",
              CorporateOperations: "",
              CompanyOrganization: "",
              Reception: "",
              Secretarial: "",
              CustomerService: ""
          },],
          QualityProductionAndRD:[ {
              QualityCertification: "",
              QualityManagement: "",
              ProjectManagement: "",
              ResearchAndDevelopment: "",
              Maintenance: "",
              Environment: "",
              Metrology: "",
              Operations: ""
          },],
          CommercialAndSales:[ {
              CommercialAgent: "",
              SalesRepresentative: "",
              ForeignTrade: "",
              KeyAccount: "",
              TelemarketingAndTelephoneSales: ""
          },],
          PurchasingLogisticsAndWarehouse:[ {
              Warehouse: "",
              PurchasingAndProcurement: "",
              DistributionAndLogistics: ""
          },],
          DesignAndGraphicArts: [{
              AudiovisualCreation: "",
              GraphicDesign: "",
              IndustrialDesign: "",
              WebDesign: "",
              PublishingAndPrinting: "",
              FashionAndTailoring: ""
          },],
          EducationAndTraining:[ {
              SpecialEducation: "",
              SocialEducation: "",
              Teaching: "",
              EducationalCenterManagement: "",
              Pedagogy: "",
              Childcare: ""
          },],
          FinanceAndBanking:[ {
              CorporateBanking: "",
              PrivateBanking: "",
              StockMarketSecuritiesAndInvestment: "",
              BankingProductsAndServices: "",
              Insurance: ""
          },],
          ITAndTelecommunications:[ {
              DatabaseAdministration: "",
              Analysis: "",
              Architecture: "",
              Quality: "",
              ERPCRMBusinessIntelligence: "",
              ProjectManagement: "",
              HardwareNetworksAndSecurity: "",
              Helpdesk: "",
              Programming: "",
              Systems: "",
              Telecommunications: ""
          },],
          EngineersAndTechnicians: [{
              Aerospace: "",
              AgronomyAndForestry: "",
              Electronics: "",
              IndustrialElectronicsAndAutomation: "",
              RenewableEnergies: "",
              GeologyGeodesyAndCartography: "",
              Industrial: "",
              Mining: "",
              NavalAndOceanEngineering: "",
              IndustrialOrganization: "",
              Chemistry: "",
              DefenseSystems: ""
          },],
          RealEstateAndConstruction:[ {
              RealEstateAdministration: "",
              ArchitectureAndDesign: "",
              ProjectAndConstructionManagement: "",
              CivilEngineeringAndPublicWorks: "",
              MaterialsEngineering: "",
              InteriorDesign: "",
              ConstructionTrades: ""
          },],
          Legal: [{
              CivilCriminalAndConstitutionalLaw: "",
              InternationalLaw: "",
              ProceduralLaw: "",
              BusinessLaw: "",
              NotaryAndRegistries: ""
          },],
          MarketingAndCommunication: [{
              CorporateCommunication: "",
              BrandAndProductDevelopment: "",
              MarketResearch: "",
              Marketing: "",
              DigitalMarketing: "",
              JournalismAndEditing: "",
              Production: "",
              Advertising: "",
              PublicRelationsAndEvents: "",
              AudiovisualTechnicians: ""
          },],
          ProfessionsArtsAndCrafts: [{
              AgricultureAndGardening: "",
              PerformingArts: "",
              PlasticArts: "",
              Craftsmanship: ""
          },],
          Automotive:[ {
              Carpentry: "",
              Tailoring: ""
          },],
          ConsumptionAndFood:[ {
              Electricity: "",
              BeautyAndCosmetology: "",
              Plumbing: "",
              Photography: "",
              Cleaning: ""
          },],
          SecurityAndSurveillance:[ {
              TranslationAndInterpretation: "",
              Transport: "",
              Others: ""
          },],
          HumanResources:[ {
              Training: "",
              RiskPrevention: "",
              LaborRelations: "",
              PersonnelSelection: ""
          },],
          HealthcareAndMedicine:[ {
              Nursing: "",
              Pharmacy: "",
              SpecializedMedicine: "",
              GeneralMedicine: "",
              AlternativeMedicine: "",
              Dentistry: "",
              Psychology: "",
              Veterinary: "",
              OtherHealthcareProfessions: ""
          },],
          TourismAndHospitality: [{
              Hospitality: "",
              Catering: "",
              Tourism: ""
          },],
          Sales: [{
              Salesperson: "",
              StoreManager: "",
              Cashier: "",
              InventoryManagement: "",
              VisualMerchandising: ""
          }],
      },
      
        employment_type: {
          fullTime:false,
          partTime:false,
      },
        job_search_status: "",
        remote_work: "",
        relocation: "",
      },
      about_you: {
        resume_url: "",
        profile_photo_url: "",
        full_name: "",
        phone_number: "",
        whatsapp_number: "",
        current_city: "",
        salary_range: {
          from:  "",
          to:  "",
        },
        linkedin_url: "",
        personal_blog_url: ""
      },
      background: {
        work_experience: [
         {
          company_name: "",
        job_title: "",
        is_current: false,
        start: dayjs(), // Initial state for start date and time
        end: dayjs(), // Initial state for end date and time (current time)
        description: "",
          }
        ],
        
        education: [
          {
            university_or_collage: "",
            graduation_year: "",
            field_of_study: "",
            degree: "",
          }
        ],
        
        language: [
          {
            language: "",
            language_level: ""
          }
        ],
        objective: "",
        achievements: "",
        personal_characteristics: ""
      },
      is_onboarding: false,
      is_active: false,
    },
    validationSchema,
    onSubmit: (values) => {
     
    },
  });


  return (
    <>
 <GlobalStyles styles={{ ".css-e1p3y6": { padding: "0 !important" } }} />
    <Box sx={{ backgroundColor: 'white', minHeight: '100vh'}}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
         
          <Preferences formik={formik} />
        
          </Grid>
        </Grid>
      </Container>
    </Box>
    </>
   

  );
};

export default Index;
