import React from 'react';
import { Container, Grid, Typography, Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#DEE4FA', // Light blue background
    padding: theme.spacing(8, 0),
  },
  header: {
    textAlign: 'left',
    marginBottom: theme.spacing(4),
  },
  mainTitle: {
    fontSize: '3rem !important',
    fontWeight: '500',
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: '1rem',
    marginBottom: theme.spacing(6),
    
  },
  section: {
    padding: theme.spacing(3),
    borderRadius: '30px !important',
    border: '1px solid #000',
    textAlign: 'left',
    backgroundColor: '#DEE4FA !important',
    height:"83%"
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  content: {
    fontSize: '1rem',
  },
}));

const SobreLaCompania = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container>
      <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
          <Typography > <span className={classes.mainTitle}>Sobre la<br/> compañía</span></Typography>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography className={classes.description}>
              Hirio es una plataforma de reclutamiento que conecta a empresas con talento cualificado de manera rápida y eficiente,
              utilizando tecnología avanzada para mejorar la experiencia de contratación.
            </Typography>
          </Grid>
        </Grid>
<br/>
      
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.section}>
              <Typography className={classes.title}><strong>MISIÓN Y VISIÓN</strong></Typography>
              <Typography className={classes.content}>
                Queremos revolucionar el proceso de contratación, haciéndolo más accesible y eficiente para empresas y candidatos. 
                Nuestra visión es liderar el reclutamiento digital a nivel global, desarrollando soluciones proactivas para un mercado 
                laboral en evolución y promoviendo un entorno laboral inclusivo y equitativo.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.section}>
              <Typography className={classes.title}><strong>NUESTRA APP</strong></Typography>
              <Typography className={classes.content}>
                Mejoramos los procesos de selección de las empresas, reduciendo costes y tiempo. Herramienta avanzada para gestionar 
                candidatos y analizar datos, asegura una mejor alineación entre las necesidades empresariales y las habilidades de los 
                candidatos. Incrementa la eficiencia y la calidad de las contrataciones, fomentando el crecimiento y la competitividad 
                de las empresas.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SobreLaCompania;
