import axios from "../axiosConfig";

class Routes {

    uploadImage = (val) => {
        let data = axios.post("/image/upload-image", val)
        return data;
    };
}



export default new Routes();