import React, { useState, useEffect } from 'react';
import { Container, Typography, Divider, Grid, Card, CardContent, CardActions, Button, Box } from '@mui/material';
import { JobApi, JobPostApi } from '../../apis';
import UploadImage from '../../components/GlobalComponent/UploadImage';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function CandidateList({ jobData, close }) {
    console.log(jobData, "jobData")
    const [loading, setLoading] = useState(true);
    const [candidates, setCandidates] = useState([]);

    const fetchJobPosts = async () => {
        try {
            const res = await JobPostApi.getSuitableUsers(jobData.id);
            if (res && res.data && res.data.data) {
                const parsedJobPosts = res.data.data.map(job => ({
                    ...job,
                    topSpecialities: parseJSON(job.profile.top_specialities),
                    employmentType: parseJSON(job.profile.employment_type),
                    salaryRange: parseJSON(job.profile.salary_range),
                }));
                setCandidates(parsedJobPosts);
            } else {
                console.error('Error fetching job posts:', res ? res.message : 'No response received');
            }
        } catch (error) {
            console.error('Error fetching job posts:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobPosts();
    }, []);

    const parseJSON = (jsonString) => {
        try {
            if (typeof jsonString === 'object') {
                return jsonString;
            }
            return jsonString ? JSON.parse(jsonString) : {};
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return {};
        }
    };

    const handleSendRequest = async (candidate) => {
        const data = {
            job_post_id: jobData.id,
            salary_range_min: jobData.jobDetails.salaryRange.from,
            salary_range_max: jobData.jobDetails.salaryRange.to,
            employment_type:
                jobData.jobDetails.location == "remote" ? "Remote" : "OnSite",
            user_id: candidate.profile.user_id,
        };

        try {
            const response = await JobApi.sendOfferRequest(data);
            close()
        } catch (error) {
            console.error('Error sending job offer request:', error);
            close()
        }
    };

    const getEmploymentType = (employmentType) => {
        if (employmentType.fullTime) return 'Full Time';
        if (employmentType.partTime) return 'Part Time';
        return 'N/A';
    };

    return (
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 0, backgroundColor: 'white' }}>
            <div style={{ position: 'relative', zIndex: 1 }}>
                <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px", marginBottom: '15px' }}>
                    View Candidates
                </Typography>
                <Divider sx={{ width: '100%', color: 'text.secondary', mb: 1 }} />
            </div>

            <div style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: '500px', position: 'relative', zIndex: 0 }}>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {candidates.length > 0 ? (
                        candidates.map(candidate => (
                            <Grid item xs={12} key={candidate.id}>
                                <Card sx={{
                                    display: { sm: 'flex' }, justifyContent: 'space-between', alignItems: 'center', padding: 2, backgroundColor: 'white',
                                    marginBottom: 2, mx: "auto"
                                }}>
                                    <Box sx={{ display: { sm: 'flex' }, alignItems: 'center', gap: 2, backgroundColor: 'white' }}>
                                        <Box
                                            sx={{
                                                width: { xs: "100%", sm: 80, md: 110 },
                                                height: { xs: 200, sm: 80, md: 110 },
                                                backgroundColor: 'primary.main',
                                                borderRadius: '8px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'lightgray',
                                                m: 1,
                                            }}
                                        >
                                            {candidate.profile && candidate.profile.profile_photo_url ? (
                                                <UploadImage
                                                    imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${candidate.profile.profile_photo_url}`}
                                                    altText={candidate.profile.profile_photo_url}
                                                />
                                            ) : (
                                                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                                    Upload
                                                </Typography>
                                            )}
                                        </Box>
                                        <CardContent sx={{ flex: { md: '1 0 auto' } }}>
                                            <Typography sx={{ fontSize: { sm: "16px", md: "22px" } }} variant="h6">{candidate.profile?.full_name || 'N/A'}</Typography>
                                            <Typography variant="body2" color="text.primary" sx={{ display: 'flex', alignItems: 'center', fontSize: { sm: "12px", md: "20px" } }}>
                                                {getEmploymentType(candidate.employmentType)} • ${candidate.salaryRange?.from || 'N/A'}-{candidate.salaryRange?.to || 'N/A'}
                                            </Typography>
                                        </CardContent>



                                    </Box>
                                    <CardActions sx={{ flexDirection: 'column' }}>
                                        <Button
                                            type="button"
                                            variant="standard"
                                            fullWidth
                                            onClick={() => handleSendRequest(candidate)}
                                            sx={{
                                                mt: 2,
                                                mb: -2,

                                                width: { sm: "130px", md: "150px" },
                                                backgroundColor: 'white',
                                                color: 'primary.main',
                                                border: '2px solid #031141',
                                                '&:hover': {
                                                    backgroundColor: 'white',
                                                }
                                            }}
                                        >
                                            <Typography component="h2" variant="body1" sx={{ fontSize: { sm: "12px", md: "15px" }, fontWeight: 'bold', textTransform: 'none', color: 'primary.main', backgroundColor: "white", padding: "2px" }}>
                                                Send Request
                                            </Typography>
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="standard"
                                            sx={{

                                                backgroundColor: "white",
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: "white"
                                                },
                                                position: 'relative',
                                                zIndex: 1,
                                                mt: 2,

                                                WebkitOverflowScrolling: 'touch'
                                            }}
                                        >
                                            <Typography component="h2" variant="body1" sx={{ fontSize: { sm: "12px", md: "15px" }, fontWeight: 'bold', textTransform: 'none', color: 'primary.main', backgroundColor: "white", padding: "2px" }}>
                                                View Resume
                                            </Typography>
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    ) : (
                        <Typography ml={1} sx={{ fontWeight: "300", fontSize: "20px", marginBottom: '15px' }}>
                            No suitable candidates available For this Job
                        </Typography>
                    )}
                </Grid>

            </div>
        </Container>
    );
}
