import React, { useEffect ,useContext} from 'react';
import { Container, Box, Typography, TextField, Button, Divider, Grid, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { JobPostApi,JobApi } from '../../apis'; // Ensure this API module is correctly implemented
import JobContext from '../../context/JobContext';

// Custom input component to format numbers with commas
const NumberFormatCustom = ({ inputRef, onChange, ...other }) => {
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator
      isNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
    />
  );
};

const ApplyForm = ({ close, candidateId }) => {
  const navigate = useNavigate();
  const {  setPendingJobData,setLoading,setJobDataArray } = useContext(JobContext);
  const fetchJobPosts = async () => {
    setLoading(true);
    try {
        const res = await JobPostApi.getAllJobPost();
        if (res && res.data && res.data.data) {
            const parsedJobPosts = res.data.data.map(job => ({
              
                ...job,
                jobDetails: parseJSON(job.jobDetails),
                requirements: parseJSON(job.requirements),
                description: parseJSON(job.description),
            }));
           
            
            setJobDataArray(parsedJobPosts);
           
          
        } else {
            console.error('Error fetching job posts:', res ? res.message : 'No response received');
        }
    } catch (error) {
        console.error('Error fetching job posts:', error);
    } finally {
        setLoading(false);
    }
};



 const parseJSON = (jsonString) => {
  try {
      // Check if jsonString is already an object
      if (typeof jsonString === 'object') {
          return jsonString; // Return as-is if already an object
      }
      return jsonString ? JSON.parse(jsonString) : {}; // Parse JSON if jsonString is not null or undefined
  } catch (error) {
      console.error('Error parsing JSON:', error);
      return {}; // Return empty object if parsing fails
  }
};

  const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
      const finalValues = {
          ...values,
          job_post_id: candidateId,
          salary_range_min: parseInt(values.salary_range_min.replace(/,/g, ''), 10),
          salary_range_max: parseInt(values.salary_range_max.replace(/,/g, ''), 10),
      };

      JobApi.applyOnJobPost(finalValues)
          .then((res) => {
              fetchJobPosts();

              JobPostApi.getJobPostById(candidateId)
                  .then((res) => {
                      
                      if (res && res.data && res.data.data) {
                          setPendingJobData(prevData => [...prevData, res.data.data]);
                         
                      }
                      close();
                  })
                  .catch((err) => {
                      console.error("API error:", err);
                  });

              close();
          })
          .catch((err) => {
              console.error("API error:", err);
          });
  };



  return (
      <Container maxWidth="md" sx={{ bg: "white" }}>
          <Formik
              initialValues={{
                  salary_range_min: '',
                  salary_range_max: '',
                  employment_type: '',
              }}
              validationSchema={Yup.object().shape({
                  salary_range_min: Yup.number().required('Minimum Salary is required').min(0, 'Minimum Salary must be a positive number'),
                  salary_range_max: Yup.number().required('Maximum Salary is required').min(0, 'Maximum Salary must be a positive number'),
                  employment_type: Yup.string().required('Employment Type is required'),
              })}
              onSubmit={handleSubmit}
          >
              {({ errors, touched, isSubmitting, setFieldValue }) => (
                  <Form>
                      <Typography variant="body1" sx={{ fontSize: '18px', fontWeight: 'bold', my: 2, }}>
                          Apply Form
                      </Typography>
                      <Divider sx={{ width: '100%', color: 'text.secondary', mb: 1 }} />

                      <Grid container spacing={2}>

                          <Grid item xs={12} sm={6}>
                              <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                  Minimum Salary *
                              </Typography>
                              <Field name="salary_range_min">
                                  {({ field }) => (
                                      <TextField
                                          {...field}
                                          fullWidth
                                          margin="normal"
                                          placeholder="Enter Minimum Salary"
                                          InputProps={{
                                              inputComponent: NumberFormatCustom,
                                          }}
                                          onChange={(e) => setFieldValue(field.name, e.target.value)}
                                      />
                                  )}
                              </Field>
                              {errors.salary_range_min && touched.salary_range_min && (
                                  <Typography variant="body2" color="error">
                                      {errors.salary_range_min}
                                  </Typography>
                              )}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                              <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                  Maximum Salary *
                              </Typography>
                              <Field name="salary_range_max">
                                  {({ field }) => (
                                      <TextField
                                          {...field}
                                          fullWidth
                                          margin="normal"
                                          placeholder="Enter Maximum Salary"
                                          InputProps={{
                                              inputComponent: NumberFormatCustom,
                                          }}
                                          onChange={(e) => setFieldValue(field.name, e.target.value)}
                                      />
                                  )}
                              </Field>
                              {errors.salary_range_max && touched.salary_range_max && (
                                  <Typography variant="body2" color="error">
                                      {errors.salary_range_max}
                                  </Typography>
                              )}
                          </Grid>

                          <Grid item xs={12}>
                              <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                  Employment Type *
                              </InputLabel>
                              <FormControl fullWidth>
                                  <Field
                                      as={Select}
                                      name="employment_type"
                                      displayEmpty
                                      renderValue={(selected) => {
                                          if (selected === "") {
                                              return <span style={{ color: '#aaa' }}>Select Employment Type</span>;
                                          }
                                          return selected;
                                      }}
                                  >
                                      <MenuItem value="" disabled>
                                          <em>Select Employment Type</em>
                                      </MenuItem>
                                      <MenuItem value="remote">Remote</MenuItem>
                                      <MenuItem value="onsite">On-site</MenuItem>
                                      <MenuItem value="hybrid">Hybrid</MenuItem>
                                  </Field>
                                  {errors.employment_type && touched.employment_type && (
                                      <Typography variant="body2" color="error">
                                          {errors.employment_type}
                                      </Typography>
                                  )}
                              </FormControl>
                          </Grid>

                      </Grid>

                      {errors.submit && (
                          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                              {errors.submit}
                          </Typography>
                      )}

                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                          <Button
                              type="submit"
                              variant="contained"
                              disabled={isSubmitting}
                              sx={{
                                  backgroundColor: 'secondary.main',
                                  color: 'white',
                                  '&:hover': {
                                      backgroundColor: 'secondary.main'
                                  }
                              }}
                          >
                              <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main' }}>
                                  Apply
                              </Typography>
                          </Button>
                      </Box>
                  </Form>
              )}
          </Formik>
      </Container>
  );
};

export default ApplyForm;
