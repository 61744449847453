import React, { useState } from 'react';
import { Container, Stepper, Typography,Box,Grid } from '@mui/material';
import CreateJobPostStep1 from '../../../../components/JobPost/Step1';
import CreateJobPostStep2 from '../../../../components/JobPost/Step2';
import CreateJobPostStep3 from '../../../../components/JobPost/Step3';
import NavBar from '../../../../components/Global/NavBar';

const steps = ['Job Details', 'Requirements', 'Description'];
const stepTexts = [
    'Job Details',
    'Requirements',
    'Description'
];

const CreateJobPost = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        jobDetails: {
            photo: '',
            positionName: '',
            location: '',
            employmentType: {
                fullTime: false,
                partTime: false,
            },
            remoteCandidates: 'no',
            willingToRelocate: false,
            salaryRange: {
                from: '',
                to: ''
            },
            specialties:"",
             experienceYears : {
                PublicAdministration:
                [
                     {
                    EuropeanAdministration: "",
                    AutonomousAdministration: "",
                    StateAdministration: "",
                    LocalAdministration: "",
                    SecurityForces: "",
                    PublicEnterprises: ""
                },
            ],
                BusinessAdministration: [{
                    Administration: "",
                    Auditing: "",
                    Consulting: "",
                    Management: "",
                    BillingCollectionsAndPayments: "",
                    FinanceAndAccounting: "",
                    NegotiationAndContracting: "",
                    CorporateOperations: "",
                    CompanyOrganization: "",
                    Reception: "",
                    Secretarial: "",
                    CustomerService: ""
                },],
                QualityProductionAndRD:[ {
                    QualityCertification: "",
                    QualityManagement: "",
                    ProjectManagement: "",
                    ResearchAndDevelopment: "",
                    Maintenance: "",
                    Environment: "",
                    Metrology: "",
                    Operations: ""
                },],
                CommercialAndSales:[ {
                    CommercialAgent: "",
                    SalesRepresentative: "",
                    ForeignTrade: "",
                    KeyAccount: "",
                    TelemarketingAndTelephoneSales: ""
                },],
                PurchasingLogisticsAndWarehouse:[ {
                    Warehouse: "",
                    PurchasingAndProcurement: "",
                    DistributionAndLogistics: ""
                },],
                DesignAndGraphicArts: [{
                    AudiovisualCreation: "",
                    GraphicDesign: "",
                    IndustrialDesign: "",
                    WebDesign: "",
                    PublishingAndPrinting: "",
                    FashionAndTailoring: ""
                },],
                EducationAndTraining:[ {
                    SpecialEducation: "",
                    SocialEducation: "",
                    Teaching: "",
                    EducationalCenterManagement: "",
                    Pedagogy: "",
                    Childcare: ""
                },],
                FinanceAndBanking:[ {
                    CorporateBanking: "",
                    PrivateBanking: "",
                    StockMarketSecuritiesAndInvestment: "",
                    BankingProductsAndServices: "",
                    Insurance: ""
                },],
                ITAndTelecommunications:[ {
                    DatabaseAdministration: "",
                    Analysis: "",
                    Architecture: "",
                    Quality: "",
                    ERPCRMBusinessIntelligence: "",
                    ProjectManagement: "",
                    HardwareNetworksAndSecurity: "",
                    Helpdesk: "",
                    Programming: "",
                    Systems: "",
                    Telecommunications: ""
                },],
                EngineersAndTechnicians: [{
                    Aerospace: "",
                    AgronomyAndForestry: "",
                    Electronics: "",
                    IndustrialElectronicsAndAutomation: "",
                    RenewableEnergies: "",
                    GeologyGeodesyAndCartography: "",
                    Industrial: "",
                    Mining: "",
                    NavalAndOceanEngineering: "",
                    IndustrialOrganization: "",
                    Chemistry: "",
                    DefenseSystems: ""
                },],
                RealEstateAndConstruction:[ {
                    RealEstateAdministration: "",
                    ArchitectureAndDesign: "",
                    ProjectAndConstructionManagement: "",
                    CivilEngineeringAndPublicWorks: "",
                    MaterialsEngineering: "",
                    InteriorDesign: "",
                    ConstructionTrades: ""
                },],
                Legal: [{
                    CivilCriminalAndConstitutionalLaw: "",
                    InternationalLaw: "",
                    ProceduralLaw: "",
                    BusinessLaw: "",
                    NotaryAndRegistries: ""
                },],
                MarketingAndCommunication: [{
                    CorporateCommunication: "",
                    BrandAndProductDevelopment: "",
                    MarketResearch: "",
                    Marketing: "",
                    DigitalMarketing: "",
                    JournalismAndEditing: "",
                    Production: "",
                    Advertising: "",
                    PublicRelationsAndEvents: "",
                    AudiovisualTechnicians: ""
                },],
                ProfessionsArtsAndCrafts: [{
                    AgricultureAndGardening: "",
                    PerformingArts: "",
                    PlasticArts: "",
                    Craftsmanship: ""
                },],
                Automotive:[ {
                    Carpentry: "",
                    Tailoring: ""
                },],
                ConsumptionAndFood:[ {
                    Electricity: "",
                    BeautyAndCosmetology: "",
                    Plumbing: "",
                    Photography: "",
                    Cleaning: ""
                },],
                SecurityAndSurveillance:[ {
                    TranslationAndInterpretation: "",
                    Transport: "",
                    Others: ""
                },],
                HumanResources:[ {
                    Training: "",
                    RiskPrevention: "",
                    LaborRelations: "",
                    PersonnelSelection: ""
                },],
                HealthcareAndMedicine:[ {
                    Nursing: "",
                    Pharmacy: "",
                    SpecializedMedicine: "",
                    GeneralMedicine: "",
                    AlternativeMedicine: "",
                    Dentistry: "",
                    Psychology: "",
                    Veterinary: "",
                    OtherHealthcareProfessions: ""
                },],
                TourismAndHospitality: [{
                    Hospitality: "",
                    Catering: "",
                    Tourism: ""
                },],
                Sales: [{
                    Salesperson: "",
                    StoreManager: "",
                    Cashier: "",
                    InventoryManagement: "",
                    VisualMerchandising: ""
                }],
            },
            
            totalYearsExperience: ''
        },
        requirements: {
            education: "",
            languages: [
                {
                    language: "",
                    level: ""
                }
            ]
        },
        description: {
            description: "",
            additionalInfo: ""
        }
    });
    

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <CreateJobPostStep1 formData={formData} setFormData={setFormData} nextStep={handleNext} />;
            case 1:
                return <CreateJobPostStep2 formData={formData} setFormData={setFormData} nextStep={handleNext} backStep={handleBack} />;
            case 2:
                return <CreateJobPostStep3 formData={formData} setFormData={setFormData} backStep={handleBack} />;
        }
    };
 
   

    return (
        <>
        <NavBar />
        <Box sx={{ backgroundColor: '#F5F5F5', minHeight: '100vh',mt:-3 }}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
              <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginBottom: '10px', mt: 3 }}>
                    Create Job Post
                </Typography>
                <Typography ml={1} sx={{ fontSize: "16px", marginBottom: '20px' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </Typography>
                <Typography ml={1} sx={{ mb: 1, textDecoration: 'none', color: 'gray', fontSize: '12px' }}>
                    Step {activeStep + 1} of 3
                </Typography>
                <Typography ml={1} sx={{ fontWeight: "Bold", fontSize: "18px", marginBottom: '10px' }}>
                    {stepTexts[activeStep]}
                </Typography>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <div key={label} style={{ flexGrow: 2,padding:"0px" }}>
                            <hr style={{ borderRadius: "4px", borderTop: `4px solid ${activeStep === index ? '#8EFFC2' : 'transparent'}`, marginTop: '10px' }} />
                        </div>
                    ))}
                </Stepper>
                <div>
                    {getStepContent(activeStep)}
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
    
      </>
      
    );
};

export default CreateJobPost;
