import React, { useState, useContext, useEffect } from 'react';
import { JobPostApi } from '../../apis';
import { Typography, Divider, Container, Grid, Card, CardContent, Avatar, IconButton, Tab, Tabs, Box, CardActions, Button, DialogActions, DialogTitle, DialogContent, Dialog, InputLabel, TextField, FormControl, Select } from '@mui/material';
import JobContext from '../../context/JobContext';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// mui styles
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import JobCard from './JobCard';

const JobOffers = () => {
    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [showCandidates, setShowCandidates] = useState(false);
    const [jobDataArray, setJobDataArray] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedJobId, setSelectedJobId] = useState(null);



    const fetchJobPosts = async () => {
        try {
            const res = await JobPostApi.getJobPost();
            if (res && res.data && res.data.jobPosts) {
                const parsedJobPosts = res.data.jobPosts.map(job => ({
                    ...job,
                    jobDetails: parseJSON(job.jobDetails),
                    requirements: parseJSON(job.requirements),
                    description: parseJSON(job.description),
                }));
                setJobDataArray(parsedJobPosts);
            } else {
                console.error('Error fetching job posts:', res ? res.message : 'No response received');
            }
        } catch (error) {
            console.error('Error fetching job posts:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchJobPosts();
    }, []);


    // Helper function to safely parse JSON
    const parseJSON = (jsonString) => {
        try {
            // Check if jsonString is already an object
            if (typeof jsonString === 'object') {
                return jsonString; // Return as-is if already an object
            }
            return jsonString ? JSON.parse(jsonString) : {}; // Parse JSON if jsonString is not null or undefined
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return {}; // Return empty object if parsing fails
        }
    };

 

    //   if (loading) {
    //     return <div>
    //          <Loader loading={loading} />
    //     </div>;
    //   }

    const handleMenuItemClick = async (id, action) => {
        setSelectedJobId(id);
        setAnchorEl(null); // Close the menu
    };
    const jobOffers = {
        accepted: [

            // Add more accepted offers
        ],
        pending: [
            // Add pending offers
        ],
        rejected: [
            // Add rejected offers
        ]
    };




    return (
 <>
     <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginBottom: '10px', marginTop: "40px" }}>
                Job Offers
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', '& .MuiTabs-flexContainer': { justifyContent: 'space-between' },minWidth:"240px" }}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="job offer tabs"
                    indicatorColor="secondary"
                >
                      <Tab
                        label={` Job Offers(${jobDataArray.length})`}
                        sx={{
                            fontSize: { xs: "10px", sm: "12px",md:"14px"},
                            color: tabValue === 0 ? 'primary.main' : 'black',
                            width: '33.33%',
                            fontWeight: tabValue === 0 ? 'bold' : 'normal'
                        }}
                    />
                    {/* <Tab

                        sx={{
                            color: tabValue === 1 ? 'primary.main' : 'black',
                            width: '33.33%',
                            fontWeight: tabValue === 1 ? 'bold' : 'normal'
                        }}
                        label={`Accepted (${jobOffers.pending.length})`}

                    />
                  
                    <Tab
                        sx={{
                            color: tabValue === 2 ? 'primary.main' : 'black',
                            width: '33.33%',
                            fontWeight: tabValue === 2 ? 'bold' : 'normal'
                        }}
                        label={`Rejected (${jobOffers.rejected.length})`}
                    /> */}
                </Tabs>
            </Box>

            {tabValue === 0 && (
                <Box sx={{ pt: 2 }}>
                    {jobDataArray.length > 0 ? (
                        jobDataArray.map(offer => (
                            <JobCard key={offer.id} offer={offer.jobDetails} offerId={offer} isPending={false} isRejected={false} />
                        ))
                    ) : (
                        <Typography sx={{ ml:{ md: 11 }}}>No job post available </Typography>
                    )}
                </Box>
            )}
            {/* {tabValue === 1 && (
                <Box sx={{ pt: 2 }}>

                    {jobOffers.accepted.map(offer => (
                        <JobCard key={offer.id} offer={offer} />
                    ))}
                </Box>
            )}
        
            {tabValue === 2 && (
                <Box sx={{ pt: 2 }}>
                    {jobOffers.rejected.map(offer => (
                        <JobCard key={offer.id} offer={offer} />
                    )
                    )}
                </Box>
            )} */}
 </>
        
     
    );
};

export default JobOffers;
