import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Typography, Avatar, Box, useTheme, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import quotation from "../../assets/images/Vector.png";

const testimonials = [
  {
    quote: "Ut ullamcorper hendrerit tempor. Aliquam in rutrum dui. Maecenas ac placerat metus.",
    name: "Robert Fox",
    title: "UI/UX Designer",
    rating: 5,
    avatar: "path_to_avatar1.jpg"
  },
  {
    quote: "Mauris eget lorem odio. Mauris convallis justo molestie metus aliquam lacinia.",
    name: "Bessie Cooper",
    title: "Creative Director",
    rating: 5,
    avatar: "path_to_avatar2.jpg"
  },
  {
    quote: "Class aptent taciti sociosqu ad litora torquent per conubia nostra.",
    name: "Jane Cooper",
    title: "Photographer",
    rating: 5,
    avatar: "path_to_avatar3.jpg"
  }
];

function TestimonialSlider() {
  const theme = useTheme();
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 960) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(3);
      }
    };

    // Call handleResize on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    adaptiveHeight: true,
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 6 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ width: '100%', textAlign: 'center', mb: 4, fontWeight: "bold" }}>
        What our users say
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <Box key={index}>
                <Box sx={{
                  width: '100%',
                  maxWidth: { xs: '50%', sm: "50%", md: '70%', lg: "70%" },
                  maxHeight: { xs: '50%', sm: "50%", md: '50%', lg: "70%" },
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  border: '2px solid #8EFFC2',
                  borderRadius: '8px',
                  m: 'auto', // Center the box horizontally
                  mb: 2,
                }}>
                  <Box sx={{ display: 'flex', mb: 1, ml: -0.5 }}>
                    {Array.from({ length: testimonial.rating }, (_, i) => (
                      <StarIcon key={i} sx={{ color: '#ffc107' }} />
                    ))}
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={{ fontStyle: 'italic', mb: 2, height: "170px", overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      "{testimonial.quote}"
                    </Typography>
                  </Box>

                  <Box sx={{ display: { xs:"block",sm: 'flex', md: 'flex', lg: 'flex' }, mt: 2 }}>
                    <Box>
                      <Avatar src={testimonial.avatar} sx={{ width: 50, height: 50, mb: 1, mr: 1 }} />
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" component="div" sx={{ mr: 10 }}>{testimonial.name}</Typography>
                      <Typography variant="caption" color="gray">{testimonial.title}</Typography>
                    </Box>
                    <img src={quotation} alt="logo" style={{ width: "30px", height: '30px', marginLeft: "auto" }} />
                  </Box>

                </Box>
              </Box>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TestimonialSlider;
