// src/context/PostProvider.js

import React, { useState } from 'react';
import PostContext from './PostContext';

const PostProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);

  const addPost = (post) => {
    setPosts(post);
  };

  const removePost = (id) => {
    setPosts(posts.filter(post => post.id !== id));
  };

  return (
    <PostContext.Provider value={{ allPosts:posts, addPost, removePost }}>
      {children}
    </PostContext.Provider>
  );
};

export default PostProvider;
