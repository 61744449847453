import React from 'react';
import NavBar from '../../../../components/Global/NavBar';
import { Grid, Typography, useMediaQuery, useTheme,Box,Container } from '@mui/material';
import JobOffers from '../../../../components/JobOffersEmployee/jobOffers';
import Footer from '../../../../components/Global/Footer';


const JobOffersPage = () => {
  
 

  return (
   
      <>
      <NavBar />
      <Box sx={{ backgroundColor: '#F5F5F5', minHeight: '100vh',mt:-6 }}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
            <JobOffers/>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
   
  );
}

export default JobOffersPage;
