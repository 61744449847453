import React from 'react';
import NavBar from '../../../../components/Global/NavBar';
import ProfileCard from '../../../../components/ProfileCard';
import CreatePost from '../../../../components/Home/CreatePost';
import SocialMediaPost from '../../../../components/Home/PostCard';
import { Box, Grid } from '@mui/material';
import Footer from '../../../../components/Global/Footer';

export default function Dashboard() {
    return (
        <>
        <Box sx={{ backgroundColor: '#F5F5F5', minHeight: '100vh'}}>
            <NavBar>
                <Box>
                <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                       
                            lg={3}
                            sx={{ mt: 2, display: {xs:"none",sm:"none", md: 'none', lg: 'block' } }}  // Hide on xs screens
                        >
                            <ProfileCard />
                        </Grid>

                        <Grid item xs={12}  lg={9}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', marginTop: '20px', width: '100%' }}>
                                <CreatePost />
                                <Box sx={{ mt: 2, width: '100%' }}>
                                    <SocialMediaPost />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </NavBar>
        </Box>

        <Footer />
    </>
    );
}
