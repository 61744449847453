import React, { useState, useContext } from 'react';
import { Typography, Box, Card, CardContent, CardActions, IconButton, Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import UploadImage from '../GlobalComponent/UploadImage';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import JobContext from '../../context/JobContext';
import { Link } from 'react-router-dom';
import ApplyForm from './CandidateList';
import { JobPostApi } from '../../apis';

const JobCard = ({ offer, offerId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCandidates, setShowCandidates] = useState(false);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewCandidates = () => {
    setShowCandidates(true);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
    <Card sx={{ display:  {sm:'flex'},justifyContent: 'space-between', alignItems: 'center', padding: 2,
           marginBottom: 2 }}>
 <Box sx={{ display:  {sm:'flex'}, alignItems: 'center', gap: 2 }}>
          <Box
          sx={{
            width:{xs:"100%",sm:80,md:110} ,
                  height: {xs:200,sm:80,md:110} ,
            backgroundColor: 'primary.main',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'lightgray',
            m: 1,
          }}
          >
            {offer && offer.photo ? (
              <UploadImage
                imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${offer.photo}`}
                altText={offer.photo}
              />
            ) : (
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                Upload
              </Typography>
            )}
          </Box>
          <CardContent sx={{flex:  {md:'1 0 auto'} }}>
            <Typography  sx={{fontSize:{sm:"18px",md:"22px"}}} variant="h6">{offer.positionName || 'N/A'}</Typography>
            <Typography variant="body2" color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <LocationOnIcon sx={{mt:{xs:-1.5,sm:0 }}} />  */}
           {offer.location || 'N/A'} • Full time • €{offer.salaryRange?.from || 'N/A'}-{offer.salaryRange?.to || 'N/A'}
            </Typography>
          </CardContent>
      
        </Box>
        <CardActions sx={{ justifyContent: 'flex-end', flexDirection: 'column' }}>
          <IconButton
            disableRipple
            aria-label="settings"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem component={Link} to={`/v1/employee/job-details/${offerId.id}`} onClick={handleClose}>
              View job details
            </MenuItem>
          </Menu>
          <Button
            variant="standard"
            fullWidth
            onClick={handleViewCandidates}
            sx={{
              mt: 2,
              mb: 2,
              width:{sm:"150px",md:"200px"},
              backgroundColor: 'white',
              color: 'primary.main',
              border: '2px solid #031141',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            <Typography component="h2" variant="body1" sx={{ fontSize:{sm:"12px",md:"15px"}, fontWeight: 'bold', textTransform: 'none', padding: '4px' }}>
              View Candidates
            </Typography>
          </Button>
        </CardActions>
      </Card>

      <Dialog
        open={showCandidates}
        onClose={() => setShowCandidates(false)}
        fullWidth
        maxWidth="md"
        sx={{
            '& .MuiPaper-root': {
              borderRadius: 4,
              border: '1px solid #e0e0e0',
              backgroundColor: 'white',
            },
          }}
      >
        <DialogTitle >
          Candidates
          <IconButton
            sx={{ position: 'absolute', top: 6, right: 6, color: 'black' }}
            onClick={() => setShowCandidates(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ApplyForm close={() => setShowCandidates(false)} jobId={offerId.id} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCandidates(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobCard;
