 // src/theme.js
import { createTheme } from '@mui/material/styles';
import '@fontsource/inter';

const theme = createTheme({
  palette: {
    primary: {
      main: '#031141',
      
    },
    secondary: {
      main: '#8EFFC2',
    },
    background: {
      default: 'white',
      paper: '#ffffff',
    },
    text: {
      primary: '#2C2C2C',
      secondary: 'white',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
  InputLabel: {
    fontFamily: 'Inter',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white', // Ensures text in buttons is white by default
        },
      },
    },
    
  },
});

export default theme;
