import React,{useEffect} from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer, Slide, toast } from 'react-toastify';
import theme from './theme';
import AppRoute from './router';
import 'react-toastify/dist/ReactToastify.css';
import { io } from 'socket.io-client';

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  rtl: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  progress: undefined,
  theme: "light",
  zIndex: 9999
};

function App() {

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer transition={Slide} {...toastOptions} />
      <AppRoute />
    </ThemeProvider>
  );
}

export default App;
