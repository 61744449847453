import axios from "../axiosConfig";

class Routes {

 
    login= (val) => {
        let data = axios.post("/auth/login", val);
        return data;
    };
    Register= (val) => {
        let data = axios.post("/auth/register", val);
        return data;
    };
    UpdatePassword= (val) => {
        let data = axios.post("/auth/reset", val);
        return data;
    };
}



export default new Routes();