import React, { useState } from 'react';
import {
    Typography, TextField, Button, Grid, Radio, RadioGroup, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem, Box, Divider
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { JobPostApi, UploadImageApi } from "../../apis";
import { OnBoardingApi } from "../../apis";
import UploadImage from '../GlobalComponent/UploadImage';
import data from '../JobPost/data.json';
import ExperienceForm from '../GlobalComponent/ExperienceForm';

const validationSchema = yup.object({
    jobDetails: yup.object().shape({

        location: yup.string().required('Location is required'),
        salaryRange: yup.object().shape({
            from: yup.string().required('Salary is required'),
            to: yup.string().required('Salary is required'),
        }),

    }),
});


const CreateJobPostStep1 = ({ formData, setFormData, nextStep }) => {
    const [selectedCategories, setSelectedCategories] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        formik.setFieldValue(`jobDetails.specialties.${name}`, checked);
        setSelectedCategories((prev) => {
            const newSelected = { ...prev };
            if (checked) {
                newSelected[name] = [];
            } else {
                delete newSelected[name];
            }
            return newSelected;
        });
    }; const getExperienceYears = (category, subcategory) => {
        return formik.values.jobDetails.experienceYears[category]?.[0]?.[subcategory] || '';
    };

    // const handleSubcategoryChange = (category, subcategory) => (event) => {
    //     formik.setFieldValue(`jobDetails.experienceYears.${subcategory}`, value);
    // };

    const handleSubcategoryChange = (category, subcategory) => (event) => {
        const value = event.target.value;

        // Update the specific subcategory value within the array of experienceYears
        const updatedExperienceYears = formik.values.jobDetails.experienceYears[category].map((item) => ({
            ...item,
            [subcategory]: value
        }));

        formik.setFieldValue(`jobDetails.experienceYears.${category}`, updatedExperienceYears);
    };
    // const handleSubcategoryChange = (category, subcategory) => (event) => {
    //     const value = event.target.value;

    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         jobDetails: {
    //             ...prevFormData.jobDetails,
    //             experienceYears: {
    //                 ...prevFormData.jobDetails.experienceYears,
    //                 [category]: {
    //                     ...prevFormData.jobDetails.experienceYears[category],
    //                     [subcategory]: value
    //                 }
    //             }
    //         }
    //     }));
    // };



    const handleProfilePhotoChange = (event) => {
        const file = event.target.files[0];
        setProfilePhoto(file);
        setProfilePhotoPreview(URL.createObjectURL(file));
    };

    const handleProfilePhotoUpload = async () => {
        if (profilePhoto) {
            try {
                const formData = new FormData();
                formData.append('file', profilePhoto);

                const res = await UploadImageApi.uploadImage(formData);
                formik.setFieldValue('jobDetails.photo', res.data.data.name);
                return res.data.data.name; // Return only the name of the uploaded photo
            } catch (error) {
                console.error("Error uploading profile photo:", error);
            }
        }
        return null;
    };

    const formik = useFormik({
        initialValues: formData,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {

                const profilePhotoName = await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
                if (profilePhotoName) {
                    values.jobDetails.photo = profilePhotoName; // Set profile_photo_url to the photo name
                }
                setFormData(values);
                nextStep();
            } catch (error) {
                // Handle API error
                console.error("Error submitting form:", error);
            }
        }
    });

    // const experienceList = ["Less than 1 year", "1-3 years", "3-5 years", "5+ years"];
    // const specialtiesList =[
    //     "PublicAdministration",
    //     "BusinessAdministration",
    //     "QualityProductionAndRD",
    //     "CommercialAndSales",
    //     "PurchasingLogisticsAndWarehouse",
    //     "DesignAndGraphicArts",
    //     "EducationAndTraining",
    //     "FinanceAndBanking",
    //     "ITAndTelecommunications",
    //     "EngineersAndTechnicians",
    //     "RealEstateAndConstruction",
    //     "Legal",
    //     "MarketingAndCommunication",
    //     "ProfessionsArtsAndCrafts",
    //     "Automotive",
    //     "ConsumptionAndFood",
    //     "SecurityAndSurveillance",
    //     "HumanResources",
    //     "HealthcareAndMedicine",
    //     "TourismAndHospitality",
    //     "Sales"
    // ]

    const specialtiesList = data.specialties || []; // Extract specialties from data.json
    const experienceList = data.experienceLevels || []; // Extract experience levels from data.json



    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    // const handleSubcategoryChange = (subcategory, value) => {
    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         jobDetails: {
    //             ...prevFormData.jobDetails,
    //             experienceYears: {
    //                 ...prevFormData.jobDetails.experienceYears,
    //                 [selectedCategory]: {
    //                     ...prevFormData.jobDetails.experienceYears[selectedCategory],
    //                     [subcategory]: value
    //                 }
    //             }
    //         }
    //     }));
    // };

    const categories = Object.keys(formData.jobDetails.experienceYears);

    const handleInputSalaryChange = (event) => {
        const { name, value } = event.target;
        let formattedValue = value.replace(/,/g, ''); // Remove existing commas
        formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas to the number
        formik.setFieldValue(name, formattedValue);
    };

    const handleInputChange = (event) => {
        const { name, checked } = event.target;
        formik.setFieldValue(`jobDetails.specialties.${name}`, checked);
    };

    const handleEmploymentTypeChange = (event) => {
        const { name, checked } = event.target;

        // Set the clicked checkbox value
        formik.setFieldValue(`jobDetails.employmentType.${name}`, checked);

        // Determine the other checkbox to uncheck
        const otherEmploymentType = name === 'partTime' ? 'fullTime' : 'partTime';

        // Uncheck the other checkbox if the clicked one is checked
        if (checked) {
            formik.setFieldValue(`jobDetails.employmentType.${otherEmploymentType}`, false);
        }
    };
    const specialtiesObject = specialtiesList.reduce((acc, specialty) => {
        acc[specialty] = true; // or any relevant details
        return acc;
    }, {});
    const handleSpecialityChange = (event) => {
        // Assuming event.target.value is a comma-separated string
        const selectedSpecialties = event.target.value.split(','); // Split the string into an array

        // Update Formik field value
        formik.setFieldValue('jobDetails.specialties', selectedSpecialties);

        // Initialize an object to hold the updated categories
        const updatedCategories = {};

        // Iterate over the selected specialties
        selectedSpecialties.forEach(specialty => {
            if (specialtiesObject[specialty]) {
                updatedCategories[specialty] = specialtiesObject[specialty];
            }
        });

        // Update the state with the selected categories
        setSelectedCategories(updatedCategories);
    };

    console.log("experienceYears", formik.values.jobDetails.experienceYears);
    const handleTotalExperienceChange = (event) => {
        const { name, value } = event.target;
        formik.setFieldValue(`jobDetails.${name}`, value);
    };
    console.log("hgdrbg", selectedCategories);
    return (
        <form onSubmit={formik.handleSubmit}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
            >
                <Grid container spacing={2}>
                    <Button component="label" variant='standard' sx={{
                        '&:hover': {
                            backgroundColor: 'transparent', // Set the background color to transparent on hover
                        },
                    }}>
                        <Grid item>
                            <Box
                                sx={{
                                    width: '100px',
                                    height: '100px',
                                    border: '1px dotted black',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '15px', ml: 1, mt: 3,
                                    backgroundColor: "lightgray"
                                }}
                            >
                                {profilePhotoPreview ? (
                                    <UploadImage
                                        imageUrl={profilePhotoPreview}
                                        altText="Profile Photo"
                                    />
                                ) : (
                                    formik.values.jobDetails.photo ? (
                                        <UploadImage
                                            imageUrl={profilePhotoPreview || `${process.env.REACT_APP_API_BASE_URL}/public/image/${formik.values.jobDetails.photo}`}
                                            altText={formik.values.jobDetails.photo}
                                        />
                                    ) : (
                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                            Upload
                                        </Typography>
                                    )
                                )}

                            </Box>
                            <input type="file" hidden onChange={handleProfilePhotoChange} />
                        </Grid>
                    </Button>

                    <Grid item>
                        <Typography variant="subtitle1" sx={{ fontWeight: "600", mt: 3 }}> Photo</Typography>
                        <Typography variant="body2" sx={{ textDecoration: 'none', color: 'gray', fontSize: '11px' }}>
                            Maximum file size is up to 10MB
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={2} sx={{ paddingTop: 1, paddingLeft: 1, backgroundColor: '#F5F5F5' }}>

                <Grid item xs={12} sm={6}>
                    <Box mt={-1.8}>
                        <InputLabel sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", marginTop: "10px" }}>
                            Profession
                        </InputLabel>
                        {/* <FormControl fullWidth>
            <Select
                name="specialties"
                value={formik.values.jobDetails.specialties}
                onChange={handleSpecialityChange}
                displayEmpty
                renderValue={(selected) => {
                    if (!selected) {
                        return <span style={{ color: '#aaa' }}>Select Total Experience</span>;
                    }
                    return selected;
                }}
            >
                <MenuItem value="" disabled>
                    <em>Select Total Experience</em>
                </MenuItem>
                {specialtiesList.map((specialty) => (
                    <MenuItem key={specialty} value={specialty}>
                        {specialty}
                    </MenuItem>
                ))}
            </Select>
            {formik.touched.jobDetails?.specialties && formik.errors.jobDetails?.specialties && (
                <Typography variant="body2" color="error">
                    {formik.errors.jobDetails.specialties}
                </Typography>
            )}
        </FormControl>
        
        {Object.keys(selectedCategories).map((category) => (
            <React.Fragment key={category}>
                <Grid item xs={12}>
                    <InputLabel
                        sx={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "text.primary",
                            marginTop: "10px",
                            mb: 2,
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            wordBreak: "break-word",
                        }}
                    >
                        How many years of experience do you have in the {category} field?
                    </InputLabel>
                </Grid>

                {formik.values.jobDetails.experienceYears[category] && formik.values.jobDetails.experienceYears[category].length > 0
                    ? (
                        <Grid container spacing={2}>
                            {Object.keys(formik.values.jobDetails.experienceYears[category][0]).map((subcategory) => (
                                <Grid item xs={12} sm={12} key={subcategory}>
                                    <InputLabel sx={{ color: 'black' }}>
                                        {subcategory}
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            name={`experienceYears.${category}[0].${subcategory}`}
                                            value={formik.values.jobDetails.experienceYears[category][0]?.[subcategory] || ''}
                                            onChange={handleSubcategoryChange(category, subcategory)}
                                            displayEmpty
                                            renderValue={(selected) => {
                                                return selected || <span style={{ color: '#aaa' }}>Select Total Experience</span>;
                                            }}
                                            aria-label={`Experience in ${subcategory}`}
                                        >
                                            <MenuItem value="" disabled>
                                                <em>Select Total Experience</em>
                                            </MenuItem>
                                            {experienceList.map((experience) => (
                                                <MenuItem key={experience} value={experience}>
                                                    {experience} years
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ))}
                        </Grid>
                    )
                    : (
                        <Typography variant="body2" color="error">
                            No subcategories found for {category}.
                        </Typography>
                    )}
            </React.Fragment>
        ))} */}
                        <ExperienceForm
                            name={"jobDetails.specialties"}
                            formik={formik}
                            specialties={specialtiesList}
                            profession={formik.values.jobDetails.specialties}
                            experienceYears={formik.values.jobDetails.experienceYears}
                            selectedCategories={selectedCategories}
                            experienceList={experienceList}
                            handleCheckboxChange={handleSpecialityChange}
                            handleSubcategoryChange={handleSubcategoryChange}

                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }} shrink={false}>
                        Location *
                    </InputLabel>
                    <FormControl fullWidth >
                        <Select
                            name="jobDetails.location"
                            value={formik.values.jobDetails.location}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected === "") {
                                    return <span style={{ color: '#aaa' }}>Select Location</span>;
                                }
                                return selected;
                            }}
                            error={formik.touched.jobDetails?.location && Boolean(formik.errors.jobDetails?.location)}
                        >
                            <MenuItem value="" disabled>
                                <em>Select Location</em>
                            </MenuItem>
                            <MenuItem value="remote">Remote</MenuItem>
                            <MenuItem value="onsite">On-site</MenuItem>
                            <MenuItem value="hybrid">Hybrid</MenuItem>
                        </Select>
                        {formik.touched.jobDetails?.location && formik.errors.jobDetails?.location ? (
                            <Typography variant="body2" color="error">
                                {formik.errors.jobDetails.location}
                            </Typography>
                        ) : null}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel mt={3} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Employment Type
                    </InputLabel>
                    <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="partTime"
                                    checked={formik.values.jobDetails.employmentType.partTime}
                                    onChange={handleEmploymentTypeChange}
                                    sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                />
                            }
                            label="Part Time"
                            sx={{ mr: 5, mb: 1 }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="fullTime"
                                    checked={formik.values.jobDetails.employmentType.fullTime}
                                    onChange={handleEmploymentTypeChange}
                                    sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                />
                            }
                            label="Full Time"
                            sx={{ mr: 5, mb: 1 }}
                        />
                    </Box>

                    {formik.touched.jobDetails?.employmentType && formik.errors.jobDetails?.employmentType && (
                        <Typography variant="body2" color="error">
                            {formik.errors.jobDetails.employmentType}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Would you consider remote candidates?
                    </InputLabel>
                    <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column' }}>
                        <RadioGroup
                            aria-label="remoteCandidates"
                            name="jobDetails.remoteCandidates"
                            value={formik.values.jobDetails.remoteCandidates}
                            onChange={formik.handleChange}
                            sx={{ flexDirection: 'row' }}
                        >
                            <FormControlLabel
                                value="Yes"
                                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                                label="Yes"
                                sx={{ marginRight: '20px' }}
                            />
                            <FormControlLabel
                                value="No"
                                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                                label="No"
                                sx={{ marginRight: '20px' }}
                            />
                            <FormControlLabel
                                value="Remote only"
                                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                                label="Remote only"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Would you consider candidates willing to relocate?
                    </InputLabel>
                    <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column' }}>
                        <RadioGroup
                            aria-label="willingToRelocate"
                            name="jobDetails.willingToRelocate"
                            value={formik.values.jobDetails.willingToRelocate}
                            onChange={formik.handleChange}
                            sx={{ flexDirection: 'row' }}
                        >
                            <FormControlLabel
                                value="Yes"
                                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                                label="Yes"
                                sx={{ marginRight: '20px' }}
                            />
                            <FormControlLabel
                                value="No"
                                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                                label="No"
                                sx={{ marginRight: '20px' }}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                        Specify the salary range *
                    </InputLabel>
                    <TextField
                        fullWidth
                        placeholder="From"
                        name="jobDetails.salaryRange.from"
                        value={formik.values.jobDetails.salaryRange.from}
                        onChange={handleInputSalaryChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.jobDetails?.salaryRange?.from && Boolean(formik.errors.jobDetails?.salaryRange?.from)}
                        helperText={formik.touched.jobDetails?.salaryRange?.from && formik.errors.jobDetails?.salaryRange?.from}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                        To *
                    </InputLabel>
                    <TextField
                        fullWidth
                        placeholder="To"
                        name="jobDetails.salaryRange.to"
                        value={formik.values.jobDetails.salaryRange.to}
                        onChange={handleInputSalaryChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.jobDetails?.salaryRange?.to && Boolean(formik.errors.jobDetails?.salaryRange?.to)}
                        helperText={formik.touched.jobDetails?.salaryRange?.to && formik.errors.jobDetails?.salaryRange?.to}
                    />
                </Grid>





                {/* Total Years of Experience */}
                <Grid item xs={12}>
                    <Box mt={2}>
                        <InputLabel sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", marginTop: "10px" }}>
                            Total Years Of Experience?
                        </InputLabel>
                        <FormControl fullWidth>
                            <Select
                                name="totalYearsExperience"
                                value={formik.values.jobDetails.totalYearsExperience}
                                onChange={handleTotalExperienceChange}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return <span style={{ color: '#aaa' }}>Select Total Experience</span>;
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem value="" disabled>
                                    <em>Select Total Experience</em>
                                </MenuItem>
                                {experienceList.map((experience) => (
                                    <MenuItem key={experience} value={experience}>
                                        {experience} years
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.jobDetails?.totalYearsExperience && formik.errors.jobDetails?.totalYearsExperience && (
                                <Typography variant="body2" color="error">
                                    {formik.errors.jobDetails.totalYearsExperience}
                                </Typography>
                            )}
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: '20px' }}>
                        <Button
                            type="Submit"
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: 'secondary.main',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'secondary.dark'
                                },
                                marginLeft: 'auto'
                            }}

                        >
                            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'text.primary', padding: "2px" }}>
                                Next: Requirements
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreateJobPostStep1;
