import React, { useEffect, useState, useRef, useContext } from 'react';
import { List, ListItem, Divider, ListItemText, Typography, Container } from '@mui/material';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { Box, Grid, ListItemIcon, Avatar, Badge, TextField, Button } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { io } from 'socket.io-client';
import JobContext from '../../context/JobContext';

// Custom styles using Material-UI
const useStyles = makeStyles((theme) => ({
    chatSection: {
        width: '100%',
        height: '550px',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 8,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    contactList: {
        width: '100%',
        border: '1px solid #e0e0e0',
        backgroundColor: '#f5f5f5',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '650px',
        },
    },
    chatArea: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '550px',
    },
    header: {
        backgroundColor: '#f5f5f5',
        color: 'black',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #e0e0e0',
        borderRight: '1px solid #e0e0e0',
    },
    messageArea: {
        flex: 1,
        overflowY: 'auto',
        padding: '10px',
        backgroundColor: '#f5f5f5',
        borderRight: '1px solid #e0e0e0',
        borderTop: '1px solid #e0e0e0',
        height: '650px',
    },
    footer: {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #e0e0e0',
        borderRight: '1px solid #e0e0e0',
        backgroundColor: '#f5f5f5',
    },
    messageBubble: {
        borderRadius: '20px',
        padding: '12px',
        marginBottom: '10px',
        wordBreak: 'break-word',
        display: 'inline-block',
        position: 'relative',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    },
    sentMessage: {
        backgroundColor: '#0A074F',
        color: 'white',
        alignSelf: 'flex-end',
        marginLeft: 'auto',
    },
    receivedMessage: {
        backgroundColor: '#ffffff',
        alignSelf: 'flex-start',
        border: '1px solid #e0e0e0',
        textAlign: 'right',
    },
}));

// ChatRooms Component
const ChatRooms = ({ userId }) => {
    const [chatRooms, setChatRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const classes = useStyles();
    const [unreadCounts, setUnreadCounts] = useState({});
    const [socket, setSocket] = useState(null);
    const { addNewMessage } = useContext(JobContext);
    const messageEndRef = useRef(null); // Ref to track the end of the message list
    const scrollToBottom = () => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        addNewMessage(false);
        scrollToBottom();
    }, [messages]);
    useEffect(() => {
        const socketInstance = io('${process.env.REACT_APP_API_BASE_URL}');
        setSocket(socketInstance); // Save socket instance
        return () => {
            socketInstance.disconnect(); // Clean up on unmount
        };
    }, []);
    useEffect(() => {
        // Fetch chat rooms when component loads
        const fetchChatRooms = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/chatrooms/${userId}`);
                setChatRooms(response.data);

                const counts = {};
                response.data.forEach(room => {
                    counts[room.id] = room.messages[0]?.unreadCount || 0;
                });
                setUnreadCounts(counts);
            } catch (error) {
                console.error('Error fetching chat rooms:', error);
            }
        };
        fetchChatRooms();
    }, [userId]);


    useEffect(() => {
        if (socket) {
            socket.on('receiveMessage', (message) => {
                // If the message is for the currently open room, append it to messages
                if (selectedRoom && message.chatRoomId === selectedRoom.id) {
                    setMessages((prevMessages) => [...prevMessages, message]);
                } else {
                    // If the message is for another room, update the unread count for that room
                    setUnreadCounts((prevCounts) => ({
                        ...prevCounts,
                        [message.chatRoomId]: (prevCounts[message.chatRoomId] || 0) + 1
                    }));
                }
            });

            // Listen for unread message notification for other rooms
            socket.on('unreadMessage', (data) => {
                if (data.receiver_id === userId) {
                    addNewMessage(true);
                }
                setUnreadCounts((prevCounts) => ({
                    ...prevCounts,
                    [data.chatRoomId]: (prevCounts[data.chatRoomId] || 0) + 1
                }));
            });
        }
        else {
            console.log("socket not found");
        }

        return () => {
            if (socket) {
                socket.off('receiveMessage');
                socket.off('unreadMessage');
            }
        };
    }, [socket, selectedRoom, unreadCounts]);

    // Handle selecting a chat room
    const handleUserClick = (room) => {
        setSelectedRoom(room);
        setMessages([]); // Clear previous messages

        // Join the selected room
        socket.emit('joinRoom', { chatRoomId: room.id });

        // Fetch message history for the selected room (optional)
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/messages/${room.id}`);
                setMessages(response.data);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };
        fetchMessages();
        const countMessages = async () => {
            try {
                await axios.put(`${process.env.REACT_APP_API_BASE_URL}/messages/mark-as-read/${room.id}/${userId}`);
                setUnreadCounts((prevCounts) => ({
                    ...prevCounts,
                    [room.id]: 0, // Reset unread count for this room
                }));
            } catch (error) {
                console.error('Error marking messages as read:', error);
            }
        }
        countMessages();
    };

    // Handle sending a message
    const handleSendMessage = () => {
        if (newMessage.trim() !== '' && selectedRoom) {
            const messageData = {
                chatRoomId: selectedRoom.id,
                senderId: userId,
                receiver_id: selectedRoom.reciverId === userId ? selectedRoom.senderId : selectedRoom.reciverId,
                message: newMessage,
            };
            socket.emit('sendMessage', messageData);
            setNewMessage(''); // Clear input
        }
    };

    return (
        <Container >
            <Grid container mt={5}>
                <Grid item xs={12} md={4} lg={4} xl={4} >
                    <Box className={classes.contactList}>
                        <Divider />
                        <Typography variant="h5" sx={{ py: 2, pl: 2, fontWeight: 'bold' }}>Chats</Typography>
                        <Divider />
                        <List>
                            {chatRooms.map((room) => (
                                console.log(room),
                                <ListItem button key={room.id} onClick={() => handleUserClick(room)}>
                                    <ListItemIcon>
                                        {
                                            // Optional chaining to avoid errors if profile or profile_photo_url doesn't exist
                                            (room.reciverId === userId ? room.sender?.profile?.profile_photo_url : room.reciver?.profile?.profile_photo_url)
                                                ? (
                                                    <Avatar
                                                        src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${room.reciverId === userId ? room.sender?.profile?.profile_photo_url : room.reciver?.profile?.profile_photo_url}`}
                                                    />
                                                ) : (
                                                    <AccountCircle sx={{ color: 'gray', width: 35, height: 35 }} />
                                                )
                                        }
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            room.reciverId === userId ? room.sender.profile.full_name : room.reciver.profile.full_name
                                        }
                                        secondary={`Job Title:` + room.job.jobDetails ? JSON.parse(room.job.jobDetails)?.positionName : 'N/A'}
                                        sx={{
                                            '& .MuiListItemText-secondary': {
                                                fontSize: '0.8rem',
                                                color: 'gray',
                                            },
                                        }}
                                    />

                                    {/*  */}
                                    <Badge badgeContent={unreadCounts[room.id] || 0} color="primary" />

                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} lg={8} xl={8} >
                    {/* Chat Area */}
                    {selectedRoom && (
                        <Box className={classes.chatArea}>
                            <Typography variant="h6" className={classes.header}>
                            Job Title: {selectedRoom.job.jobDetails ? JSON.parse(selectedRoom.job.jobDetails)?.positionName : 'N/A'}
                            </Typography>
                            <Box className={classes.messageArea}>
                                <List>
                                    {messages.map((msg, index) => (
                                        <ListItem key={index}>
                                            <Typography className={`${classes.messageBubble} ${userId === msg.senderId ? classes.sentMessage : classes.receivedMessage}`}>
                                                {msg.message}
                                            </Typography>
                                        </ListItem>
                                    ))}

                                </List>
                                <p ref={messageEndRef}></p>
                            </Box>
                            <Box className={classes.footer}>
                                <TextField
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    fullWidth
                                    placeholder="Type a message..."
                                />
                                <Button onClick={handleSendMessage} variant="contained" color="primary">
                                    Send
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Grid>

            </Grid>
        </Container>
    );
};

export default ChatRooms;
