import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Grid,
    InputLabel,
    Divider,Container
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { OnBoardingApi } from "../../../../apis";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import UploadImage from "../../../../components/GlobalComponent/UploadImage";
import AddIcon from '@mui/icons-material/Add';
import Loader from "../../../../components/GlobalComponent/Loader";


const PreviewAndSubmit = () => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate(); // Initialize navigate
    const location = useLocation();
    const [formikValues, setFormikValues] = useState(location.state?.formikValues || {});

    useEffect(() => {
        if (!formikValues || Object.keys(formikValues).length === 0) {
            console.error("No form data available");
        }
    }, [formikValues]);
   
    const submitForm = async (values) => {
        try {
            // Convert top_specialities to JSON string
            const updatedValues = {
                ...values,
                preferences: {
                    ...values.preferences,
                    top_specialities: JSON.stringify(values.preferences.top_specialities),
                    employment_type: JSON.stringify(values.preferences.employment_type)
                }
            };

            const res = await OnBoardingApi.OnBoarding(updatedValues);
  
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        const fetchOnBoardedUserData = async () => {
            try {
                const res = await OnBoardingApi.getOnBoarding(); // Adjust API call as needed
                
                setUserData(multiParse(res?.data?.profile));
                console.log("cjdsvnf",res?.data?.profile);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching on-boarded user data:", error);
                setLoading(false);
            }
        };
        fetchOnBoardedUserData();
    }, []);
    if (loading) {
        return <div>
             <Loader loading={loading} />
        </div>;
      }

      function multiParse(input) {
        let result = input;
        try {
            while (typeof result === 'string') {
                result = JSON.parse(result);
            }
        } catch (e) {
            console.error('Error parsing input:', e);
        }
        return result;
    }
  
    return (
        <>

        <Box sx={{ backgroundColor: 'white', minHeight: '100vh',padding:0 }}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
              <Box sx={{mx: {lg:"150px"}}}>
      
      <Box
          sx={{
              display: "flex",
              justifyContent: "space-between",
          }}
      >
          <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
              Profile Preview
          </Typography>
      </Box>
      <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px", marginLeft: '-4px', marginBottom: '15px' }}>
          Account
      </Typography>
      <Box
          sx={{
              display: 'flex',
              justifyContent: 'flex-start', // Align to the left
          }}
      >
          <Grid container spacing={2}>
              <Grid item>
                  <Box
                      sx={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid black",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "15px",
                      }}
                  >
                      {userData?.profile_photo_url ? (

                          <UploadImage
                              imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData?.profile_photo_url}`}
                              altText={userData?.profile_photo_url}
                              style={{ maxWidth: "100%", maxHeight: "100%", objectFit: 'cover', borderRadius: '5px' }}
                          />
                      ) : (
                          <AddIcon sx={{ fontSize: 30, color: "black" }} />
                      )}
                  </Box>
              </Grid>
              <Grid item>
                  <Typography sx={{ fontWeight: "600", fontSize: "16px" }}> {userData?.full_name || "N/A"}</Typography>
                  <Typography variant="body2" sx={{ mr: 0, textDecoration: 'none', color: 'gray', fontSize: '11px' }}>
                      {userData?.current_city || "N/A"}
                  </Typography>
                  <Typography variant="body2" sx={{ mr: 0, textDecoration: 'none', color: 'gray', fontSize: '11px' }}>
                      {userData?.whatsapp_number || "N/A"}
                  </Typography>
                  <Typography variant="body2" sx={{ mr: 0, textDecoration: 'none', color: 'gray', fontSize: '11px' }}>
                      {userData?.phone_number || "N/A"}
                  </Typography>
              </Grid>
          </Grid>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
              Qualification
          </Typography>
          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
              Professional Area
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
              {userData?.professional_area || "N/A"}
          </InputLabel>
          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
              Total Years of Experience
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
              {userData?.totalYearsExperience || "N/A"}
          </InputLabel>

          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                      Top Specialities
                  </Typography>
                  <Typography variant="body1" paragraph>
                      <strong>Specialties: </strong>
                      <Box component="span">
                          {(() => {
                                  
                              const parsedSpecialties = multiParse(userData.top_specialities); // Assuming it's already parsed
                              console.log('Parsed Specialties:', parsedSpecialties);

                              const hasValidSpecialties = Object.entries(parsedSpecialties).some(([_, value]) => value);
                              console.log('Has Valid Specialties:', hasValidSpecialties);

                              return hasValidSpecialties ? (
                                  (parsedSpecialties).map(([specialty, value]) => (
                                      value ? (
                                          <Typography key={specialty} component="span" variant="body1" sx={{ display: 'inline', mr: 1 }}>
                                              <br /> {specialty.replace(/([A-Z])/g, ' $1').trim()}
                                          </Typography>
                                      ) : null
                                  ))
                              ) : (
                                  <Typography component="span" variant="body1">
                                      N/A
                                  </Typography>
                              );
                          })()}
                      </Box>
                  </Typography>
<Typography variant="body1" paragraph>
<strong>Experience Years:</strong>
<Box component="span">
{userData?.experienceYears ? (
Object.entries(multiParse(userData?.experienceYears)) // Parse the JSON string
.flatMap(([specialty, yearsOrSubcategories]) => {

// Check if yearsOrSubcategories is an object (representing subcategories)
if (Array.isArray(yearsOrSubcategories)) {

  return yearsOrSubcategories.flatMap(subcategory =>
    Object.entries(subcategory)
      .filter(([subSpecialty, years]) => years !== "")
      .map(([subSpecialty, years]) => (
        <Typography key={subSpecialty} variant="body2" paragraph sx={{ display: 'inline', mr: 1 }}>
          <br/>  <strong>{subSpecialty}:</strong> {years}
        </Typography>
      ))
  );
} else if (yearsOrSubcategories !== "") {
  // If it's a direct value, render it
  return (
    <Typography key={specialty} variant="body2" paragraph sx={{ display: 'inline', mr: 1 }}>
     <br/> <strong>{specialty}:</strong> {yearsOrSubcategories}
    </Typography>
  );
}
return []; // Return an empty array if the value is empty
})
) : (
"N/A"
)}
</Box>
</Typography>


      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
              Job Preferences
          </Typography>

          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.1 }}>
              Type of Employment
          </InputLabel>
          <Box mt={2}>
              {userData?.employment_type ? (
                  <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", mt: -1, ml: -0.1 }}>
                      {JSON.parse(userData.employment_type)["fullTime"] ? "Full Time" : ""}
                      {JSON.parse(userData.employment_type)["fullTime"] && JSON.parse(userData.employment_type)["partTime"] ? ", " : ""}
                      {JSON.parse(userData.employment_type)["partTime"] ? "Part Time" : ""}
                  </Typography>
              ) : (
                  <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                      N/A
                  </Typography>
              )}
          </Box>
          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.1 }}>
              Job Search Status
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary", ml: -0.1 }}>
              {userData?.job_search_status || "N/A"}
          </InputLabel>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px', ml: -1 }}>
              Location
          </Typography>
          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.5 }}>
              Remote Work
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
              {userData?.remote_work ? "Yes" : "No"}
          </InputLabel>
          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.5 }}>
              Relocation
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
              {userData?.relocation ? "Yes" : "No"}
          </InputLabel>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
              Salary Expectation
          </Typography>
          <Box mt={2}>
              <InputLabel sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.5 }}>
                  Your Preferred Net Salary
              </InputLabel>
              {userData?.salary_range ? (
                  <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.3 }}>
                      <strong> From:</strong> {
                        // only parse if it's a string
                        typeof userData.salary_range === 'string' ? JSON.parse(userData.salary_range).from : userData.salary_range.from


                      }  <strong> To:</strong> 

                        {
                        // only parse if it's a string
                        typeof userData.salary_range === 'string' ? JSON.parse(userData.salary_range).to : userData.salary_range.to
                            
                        }
                  </Typography>

              ) : (
                  <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                      N/A
                  </Typography>
              )}
          </Box>
      </Box>

      <Divider sx={{ my: 2 }} />
      <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
              Links
          </Typography>
          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
              {userData?.linkedin_url || "N/A"}
          </Typography>
          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
              {userData?.personal_blog_url || "N/A"}
          </Typography>
          {/* Add Link Details */}
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
              Work Experience
          </Typography>
          {userData?.work_experience ? (
              (() => {
                  function multiParse(input) {
                      let result = input;
                      try {
                        while (typeof result === 'string') {
                          result = JSON.parse(result);
                        }
                      } catch (e) {
                        console.error('Error parsing input:', e);
                      }
                      return result;
                    }
                    
                  const parsedWorkExperience = multiParse(JSON.parse(JSON.stringify(userData.work_experience)));
                  const allCompanies = parsedWorkExperience.map(exp => exp.company_name || "N/A").join(", ");
                  const allJobTitles = parsedWorkExperience.map(exp => exp.job_title || "N/A").join(", ");
                  const allStartDates = parsedWorkExperience.map(exp => exp.start || "N/A").join(", ");
                  const allEndDates = parsedWorkExperience.map(exp => exp.end || "N/A").join(", ");
                  const allDescriptions = parsedWorkExperience.map(exp => exp.description || "N/A").join(", ");
                  return (
                      <Box mt={2}>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>Company Name:</strong> {allCompanies}
                          </Typography>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>Job Title:</strong> {allJobTitles}
                          </Typography>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>Start Date:</strong> {allStartDates}
                          </Typography>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>End Date:</strong> {allEndDates}
                          </Typography>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>Description:</strong> {allDescriptions}
                          </Typography>
                      </Box>
                  );
              })()
          ) : (
              <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                  N/A
              </Typography>
          )}

      </Box>

      <Divider sx={{ my: 2 }} />
      <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
              Education
          </Typography>
          {userData?.education ? (
              (() => {
                  function multiParse(input) {
                      let result = input;
                      try {
                        while (typeof result === 'string') {
                          result = JSON.parse(result);
                        }
                      } catch (e) {
                        console.error('Error parsing input:', e);
                      }
                      return result;
                    }
                  const parsedEducation = multiParse(JSON.stringify(userData.education));
                  const allUniversities = parsedEducation.map(edu => edu.university_or_collage || "N/A").join(", ");
                  const allGraduationYears = parsedEducation.map(edu => edu.graduation_year || "N/A").join(", ");
                  const allFieldsOfStudy = parsedEducation.map(edu => edu.field_of_study || "N/A").join(", ");
                  const allDegrees = parsedEducation.map(edu => edu.degree || "N/A").join(", ");
                  return (
                      <Box mt={2}>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>University or College:</strong> {allUniversities}
                          </Typography>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>Graduation Year:</strong> {allGraduationYears}
                          </Typography>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>Field of Study:</strong> {allFieldsOfStudy}
                          </Typography>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>Degree:</strong> {allDegrees}
                          </Typography>
                      </Box>
                  );
              })()
          ) : (
              <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                  N/A
              </Typography>
          )}

        
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
              Language
          </Typography>
          {userData?.languages ? (
              (() => {
                  function multiParse(input) {
                      let result = input;
                      try {
                        while (typeof result === 'string') {
                          result = JSON.parse(result);
                        }
                      } catch (e) {
                        console.error('Error parsing input:', e);
                      }
                      return result;
                    }
                  const parsedLanguages = multiParse(JSON.stringify(userData.languages));
                  const allLanguages = parsedLanguages.map(language => language.language || "N/A").join(", ");
                  const allLevels = parsedLanguages.map(language => language.language_level || "N/A").join(", ");
                  return (
                      <Box mt={2}>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>Languages:</strong> {allLanguages}
                          </Typography>
                          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                              <strong>Levels:</strong> {allLevels}
                          </Typography>
                      </Box>
                  );
              })()
          ) : (
              <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                  N/A
              </Typography>
          )}

        
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
              Additional Information
          </Typography>
          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
              Objective
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
              {userData?.objective || "N/A"}
          </InputLabel>
          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
              Achievements
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
              {userData?.achievements || "N/A"}
          </InputLabel>
          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
              Personal Characteristics
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
              {userData?.personal_characteristics || "N/A"}
          </InputLabel>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box
          sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 3,
          }}
      >
          <Button
              type="submit"
              onClick={() => navigate("/v1/employee/background")}
              variant="outlined"
              sx={{
                  mt: 3, mb: 2, backgroundColor: 'white', borderColor: 'primary.main', color: 'text.secondary', '&:hover': {
                      backgroundColor: 'text.secondary'
                  }
              }}
          >
              <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                  Back
              </Typography>
          </Button>
          <Button
              type="submit"
              variant="contained"
              onClick={async () => {
                  formikValues.is_onboarding = true;
                  localStorage.setItem("onboarding", true);
                //   is_onboarding = true;
                    localStorage.setItem("is_onboarding", true);
                  await submitForm(formikValues);
                  window.location.href = "/v1/employee/home";
              }}
              sx={{
                  mt: 3, mb: 2, backgroundColor: 'secondary.main', color: 'white', '&:hover': {
                      backgroundColor: 'secondary.main'
                  }
              }}
          >
              <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                  Submit
              </Typography>
          </Button>
      </Box>
  </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        </>
    
      
    );
};

export default PreviewAndSubmit;
