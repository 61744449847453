import React, { useState, useContext,useEffect } from 'react';
import { Card, CardHeader, Avatar, CardContent, Typography, Divider, Box, CardMedia, Paper, Grid } from '@mui/material';
import { PostApi } from '../../apis';
import PostContext from '../../context/PostContext';
import Loader from '../GlobalComponent/Loader';

const formatHour = (timeString) => {
  // get the time spent form now to the time the post was created
  const currentTime = new Date();
  const postTime = new Date(timeString);
  const timeDifference = Math.abs(currentTime - postTime);
  const timeDifferenceInHours = timeDifference / (1000 * 3600);
   if (timeDifferenceInHours < 1) {
    return Math.floor(timeDifferenceInHours * 60) + ' minutes';
  }
  else if (timeDifferenceInHours < 24) {
    return Math.floor(timeDifferenceInHours) + ' hours';
  }
  else {
    return Math.floor(timeDifferenceInHours / 24) + ' days';
  }

};

const getFirstPartOfEmail = (email) => {
  return email.split('@')[0];
};

export default function SocialMediaPost() {
  const { allPosts, removePost } = useContext(PostContext);

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPosts = async () => {
    try {
      const res = await PostApi.getPost();
      setPosts(res.data.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    setPosts(allPosts);
  }, [allPosts]);

  if (loading) {
    return <div>
         <Loader loading={loading} />
    </div>;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12}>
        <Box >
          <Paper
            sx={{
              
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              border: 'none',
              boxShadow: 'none',
              backgroundColor: "#F5F5F5",
             
            }}
          >
            {posts.map((post, index) => (
              <Card key={index} sx={{ width: '100%', margin: 'auto', mb: 2 , maxWidth:"840px",  border: '1px solid #e0e0e0',boxShadow: 'none',
                borderRadius: '16px',}}>
                <CardHeader
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  avatar={
                    <Avatar src={post.avatarSrc || '/path/to/default/avatar.jpg'} aria-label="profile picture">
                      {post.poster && post.poster.name ? post.poster.name[0] : 'R'}
                    </Avatar>
                  }
                  title={post.poster ? getFirstPartOfEmail(post.poster.email) : 'Unknown'}
                  subheader={
                    <Typography sx={{ color: 'gray', fontSize: '12px' }}>
                      {post.jobTitle || 'Job Title'}
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ cursor: 'pointer', color: 'gray', fontSize: '12px', mr: 1 }}>
                          {formatHour(post.createdAt) } ago
                        </Typography>
                        {post.isFollowing && (
                          <Typography variant="body2" sx={{ cursor: 'pointer', color: 'secondary.main', fontSize: '12px' }}>
                            Following
                          </Typography>
                        )}
                      </Box>
                    </Typography>
                  }
                />
                <Divider sx={{ width: '90%', color: 'text.secondary', ml: 4 }} />
                <CardContent sx={{ ml: 1 }}>
                  <Typography variant="body2" color="black" sx={{ fontSize: '13px' }}>
                    {post.content || ''}
                  </Typography>
                </CardContent>
                {post.image && (
                  <Box sx={{ alignItems: 'center' }}>
                    <CardMedia
                      component="img"
                      height="300"
                      image={`${process.env.REACT_APP_API_BASE_URL}/public/image/${post.image}`}
                      alt={post.image}
                    />
                  </Box>
                )}
              </Card>
            ))}
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}
