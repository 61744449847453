import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Avatar, Box, Divider, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { OnBoardingApi } from "../../apis";
import userAvatar from "../../assets/images/avatar.png";
import Loader from '../GlobalComponent/Loader';

export default function ProfileCard() {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  let user;

  try {
    const userString = localStorage.getItem("user");
    user = JSON.parse(userString);
  } catch (error) {
    console.error("Error parsing user from localStorage:", error);
  }


  const isEmployee = user && user?.role?.name === "employee";
  const isRecruiter = user && user?.role?.name === "recruiter";
  const navigate = useNavigate();

  const handleClick = () => {
    if (isRecruiter) {
     
      navigate("/v1/recruiter/my-profile");
    } else if (isEmployee) {
      navigate("/v1/employee/my-profile");
    } else {
      console.warn("User role not recognized:", user);
    }
  };



  const fetchOnBoardedUserData = async () => {
    try {
      const res = await OnBoardingApi.getOnBoarding();
      setUserData(res.data.profile);
    } catch (error) {
      console.error("Error fetching on-boarded user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOnBoardedUserData();
  }, []);

 

  if (loading) {
    return <div>
         <Loader loading={loading} />
    </div>;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={6} md={6} lg={12}>
        <Card sx={{ position: "relative", marginTop: "15px", borderRadius: '16px', boxShadow: 3, width: '100%' }}>
          <Box sx={{ width: '100%', height: '80px', backgroundColor: 'white', borderRadius: '16px 16px 0 0', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
            {/* <img src={profileHeader} alt="Your Image" style={{ maxWidth: '100%', maxHeight: '100%' }} /> */}
          </Box>

          <CardContent sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            {userData?.profile_photo_url ? (
              <Avatar
                src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData.profile_photo_url}`}
                alt={userData?.profile_photo_url}
                sx={{ position: "absolute", top: "10%", width: 80, height: 80 }}
              />
            ) : (
              <Avatar
                src={userAvatar}
                alt="upload"
                sx={{ position: "absolute", top: "10%", width: 80, height: 80 }}
              />
            )}

            <Typography variant="h6" component="div" sx={{ fontWeight: "bold", mt: 2 }}>
              {userData?.full_name}
            </Typography>
            <Typography variant="body2" color="#AAAAAA" sx={{ mb: 1.5, fontSize: "16px" }}>
              {userData?.company_name}
            </Typography>
            <Divider sx={{ width: '100%', backgroundColor: 'secondary.main' }} />

            <Button
              type="button"
              onClick={handleClick}
              variant="standard"
              fullWidth
              sx={{
                mt: 2,
                mb: 2,
                backgroundColor: 'secondary.main',
                color: 'text.primary',
                border: '2px solid #8EFFC2',
                '&:hover': {
                  backgroundColor: 'secondary.main',
                }
              }}
            >
              <Typography component="h2" variant="body1" sx={{ color: 'primary.main', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', padding: "4px" }}>
                View Profile
              </Typography>
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
