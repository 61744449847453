
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Grid,
    TextField,
    InputLabel,
    FormControl,
    Chip,
    Divider,
    Select, MenuItem,Container,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Autocomplete from "react-google-autocomplete";
import { OnBoardingApi, UploadImageApi } from "../../../../apis";
import AddIcon from "@mui/icons-material/Add";
import UploadImage from "../../../../components/GlobalComponent/UploadImage";
import CustomAutocomplete from '../../../../components/GlobalComponent/customAutoComplete';

const CompanyProfile = () => {
    const [companyProfilePhoto, setCompanyProfilePhoto] = useState(null);
    const [companyProfilePhotoPreview, setCompanyProfilePhotoPreview] = useState(null);

    const handleCompanyProfilePhotoChange = (event) => {
        const file = event.target.files[0];
        setCompanyProfilePhoto(file);
        setCompanyProfilePhotoPreview(URL.createObjectURL(file));
    };

    const handleCompanyProfilePhotoUpload = async () => {
        if (companyProfilePhoto) {
            try {
                const formData = new FormData();
                formData.append("file", companyProfilePhoto);

                const res = await UploadImageApi.uploadImage(formData);
     
                return res.data.data.name; // Return only the name of the uploaded photo
            } catch (error) {
                console.error("Error uploading profile photo:", error);
                throw error;
            }
        }
    };

    const navigate = useNavigate();

    const CompanyProfileForm = ({ formik }) => {
        const { values, handleChange } = formik;

        const [loading, setLoading] = useState(true);

        useEffect(() => {
            const fetchOnBoardedUserData = async () => {
                try {
                    const authToken = localStorage.getItem("token");
                    const res = await OnBoardingApi.getOnBoarding(authToken);
                    

                    // Check if company_perks exists and is a non-empty string before parsing
                    const companyPerks = res.data.profile.company_perks &&
                        typeof res.data.profile.company_perks === "string"
                        ? JSON.parse(res.data.profile.company_perks)
                        : {};
             

                    formik.setValues(prevValues => ({
                        ...prevValues,
                        company_profile: {
                            ...prevValues.company_profile,
                            company_profile_photo_url: res.data.profile.company_profile_photo_url || "",
                            company_name: res.data.profile.company_name || "",
                            // company_slogan: res.data.profile.company_slogan || "",
                            company_location: res.data.profile.company_location || "",
                            company_size: res.data.profile.company_size || "",
                            company_domain: res.data.profile.company_domain || "",
                            company_overview: res.data.profile.company_overview || "",
                            company_website: res.data.profile.company_website || "",
                            company_perks: res.data.profile.company_perks || companyPerks,
                        },
                    }));

                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching on-boarded user data:", error);
                    setLoading(false);
                }
            };

            fetchOnBoardedUserData();
        }, []);




        const handlePerkChange = (perkName) => {
            const updatedPerks = {
                ...values.company_profile.company_perks,
                [perkName]: !values.company_profile.company_perks[perkName],
            };

           

            handleChange({
                target: {
                    name: "company_profile.company_perks",
                    value: updatedPerks,
                },
            });
        };


        const companyPerksList = [
            { label: "Flexible working hours", name: "flexible_working_hours" },
            { label: "Remote work", name: "remote_work" },
            { label: "Medical insurance", name: "medical_insurance" },
            { label: "Paid vacation", name: "paid_vacation" },
            { label: "Paid sick leaves", name: "paid_sick_leaves" },
            { label: "Parental leave", name: "parental_leave" },
            { label: "Corporate events", name: "corporate_events" },
            { label: "Team-buildings", name: "team_buildings" },
            { label: "Sport activities", name: "sport_activities" },
            { label: "Lunch compensation", name: "lunch_compensation" },
            { label: "Free meals", name: "free_meals" },
            { label: "Wellness program", name: "wellness_program" },
        ];

        return (
            <div>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                        ml={1}
                        sx={{
                            fontWeight: "600",
                            fontSize: "22px",
                            marginLeft: "-4px",
                            marginBottom: "15px",
                        }}
                    >
                        Tell us about the company you are hiring for
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box
                                sx={{
                                    width: "100px",
                                    height: "100px",
                                    border: "1px dotted black",
                                    borderRadius: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "15px",
                                }}
                            >
                                <Button>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="company-profile-photo-upload"
                                        onChange={handleCompanyProfilePhotoChange}
                                    />
                                    <label htmlFor="company-profile-photo-upload">
                                        <Button component="span" sx={{
                                            '&:hover': {
                                                backgroundColor: 'transparent', // Set the background color to transparent on hover
                                            },
                                        }}>
                                            {companyProfilePhotoPreview ? (
                                                <UploadImage
                                                    imageUrl={companyProfilePhotoPreview}
                                                    altText="Company Logo"
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        borderRadius: "5px",
                                                    }}
                                                />
                                            ) : values.company_profile.company_profile_photo_url ? (
                                                <UploadImage
                                                    imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${values.company_profile.company_profile_photo_url}`}
                                                    altText={values.company_profile.company_profile_photo_url}
                                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                
                                                />
                                            ) : (
                                                <AddIcon sx={{ fontSize: 30, color: "black" }} />
                                            )}
                                        </Button>
                                    </label>
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                                Company Logo
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    mr: 0,
                                    textDecoration: "none",
                                    color: "gray",
                                    fontSize: "11px",
                                }}
                            >
                                Maximum file size is up to 10MB
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>


                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                Company Name *
                            </InputLabel>
                            <TextField
                                name="company_profile.company_name"
                                value={values.company_profile.company_name}
                                onChange={handleChange}
                                fullWidth
                                placeholder="Company Name"
                                variant="outlined"

                                InputLabelProps={{
                                    shrink: false,
                                    style: { fontSize: '14px', color: 'gray' }
                                }}
                            />
                            {formik.errors.company_profile && (
                                <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                    {formik.errors.company_profile.company_name}
                                </Typography>
                            )}
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                Company Slogan *
                            </InputLabel>
                            <TextField
                                name="company_profile.company_slogan"
                                value={values.company_profile.company_slogan}
                                onChange={handleChange}
                                placeholder="Company Slogan"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: false,
                                    style: { fontSize: '14px', color: 'gray' }
                                }}
                                sx={{ mb: 2 }}
                            />
                            {formik.errors.company_profile && (
                                <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px", mt: -1 }}>
                                    {formik.errors.company_profile.company_slogan}
                                </Typography>
                            )}
                        </Grid> */}
                    </Grid>
                </Box>

                <Box mt={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} sx={{ fontWeight: '600', fontSize: '14px', color: 'text.primary', mb: 0.5 }}>
                                Location *
                            </InputLabel>
                            <CustomAutocomplete
                                formik={formik}
                                name="company_profile.company_location"
                                value={values.company_profile.company_location}
                                onChange={handleChange}
                            />
                            {formik.errors.company_profile && (
                                <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: '12px', }}>
                                    {formik.errors.company_profile.company_location}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                Company Size *
                            </InputLabel>
                            <FormControl fullWidth variant="outlined">
                                <Select
                                    name="company_profile.company_size"
                                    value={values.company_profile.company_size}
                                    onChange={handleChange}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected === "") {
                                            return <span style={{ color: '#aaa' }}>Select Company Size</span>;
                                        }
                                        return selected;
                                    }}
                                >
                                    <MenuItem value="" disabled>Select company size</MenuItem>
                                    <MenuItem value="Small">Small</MenuItem>
                                    <MenuItem value="Medium">Medium</MenuItem>
                                    <MenuItem value="Big">Big</MenuItem>
                                </Select>
                            </FormControl>
                            {formik.errors.company_profile && (
                                <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px", }}>
                                    {formik.errors.company_profile.company_size}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                        Company Domain *
                    </Typography>
                    <TextField
                        name="company_profile.company_domain"
                        value={values.company_profile.company_domain}
                        onChange={handleChange}
                        placeholder="Company Domain"
                        variant="outlined"
                        fullWidth
                    
                        InputLabelProps={{
                            shrink: false,
                            style: { fontSize: '14px', color: 'gray' }
                        }}
                        sx={{ mb: 2 }}
                    />
                    {formik.errors.company_profile && (
                        <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px", mt: -1 }}>
                            {formik.errors.company_profile.company_domain}
                        </Typography>
                    )}
                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                        Company Overview
                    </Typography>
                    <TextField
                        name="company_profile.company_overview"
                        value={values.company_profile.company_overview}
                        onChange={handleChange}
                        placeholder="Company Overview"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        InputLabelProps={{
                            shrink: false,
                            style: { fontSize: '14px', color: 'gray' }
                        }}
                        sx={{ mb: 2 }}
                    />
                    {formik.errors.company_profile && (
                        <Typography color="error" variant="body2">
                            {formik.errors.company_profile.company_overview}
                        </Typography>
                    )}
                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                        Website *
                    </Typography>
                    <TextField
                        name="company_profile.company_website"
                        value={values.company_profile.company_website}
                        onChange={handleChange}
                        placeholder="Enter website name"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: false,
                            style: { fontSize: '14px', color: 'gray' }
                        }}
                        sx={{ mb: 2 }}
                    />
                    {formik.errors.company_profile && (
                        <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px", mt: -1 }}>
                            {formik.errors.company_profile.company_website}
                        </Typography>
                    )}
                </Box>

                <Box>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px', mb: 0.5 }}>
                        Company Perks
                    </Typography>
                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                        Choose the perks your company offers
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {companyPerksList.map((perk) => (
                            <Chip
                                key={perk.name}
                                label={perk.label}
                                onClick={() => handlePerkChange(perk.name)}
                                color={values.company_profile.company_perks[perk.name] ? "primary" : "default"}
                                sx={{ mr: 1, mb: 1 }}
                            />
                        ))}
                    </Box>
                </Box>




            </div>
        );
    };


    const submitForm = async (values) => {
        try {
            const companyProfilePhotoName = await handleCompanyProfilePhotoUpload();

            if (companyProfilePhotoName) {
                values.company_profile.company_profile_photo_url = companyProfilePhotoName;
            }
            const res = await OnBoardingApi.OnBoarding(values);

  
        } catch (err) {
            console.error(err);
            throw err; // Re-throw the error to be caught by Formik's onSubmit handler
        }
    };
    const validationSchema = yup.object().shape({
        company_profile: yup.object().shape({
            company_name: yup.string().required("Company name is required"),
            // company_slogan: yup.string().required("Company slogan is required"),
            company_location: yup.string().required("Company location is required"),
            company_size: yup.string().required("Company size is required"),
            company_domain: yup.string().required("Company domain is required"),
            company_website: yup.string().required("Company website URL is required"),
        }),
    });

    const [formikValues, setFormikValues] = useState({
        company_profile: {
            company_profile_photo_url: "",
            company_name: "",
            // company_slogan: "",
            company_location: "",
            company_size: "",
            company_domain: "",
            company_overview: "",
            company_website: "",
            company_perks: {
                flexible_working_hours: false,
                remote_work: false,
                medical_insurance: false,
                paid_vacation: false,
                paid_sick_leaves: false,
                parental_leave: false,
                corporate_events: false,
                team_buildings: false,
                sport_activities: false,
                lunch_compensation: false,
                free_meals: false,
                wellness_program: false,
            },
        },
    });

    return (
        <>
        <Box sx={{ backgroundColor: 'white', minHeight: '100vh',padding:0 }}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
              <Box sx={{mx: {lg:"150px"}}}>
         
                <Formik
                    initialValues={formikValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            await submitForm(values);
                            navigate("/v1/recruiter/preview-and-submit", {
                                state: { formikValues: values },
                            });
                        } catch (error) {
                            console.error("Error submitting form:", error);
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                    validateOnChange={false}
                    validateOnBlur={false} // Disable validation on blur to prevent premature error display
                >
                    {(formik) => (
                        <Form>
                            <CompanyProfileForm formik={formik} />
                            <Divider sx={{ my: 2 }} />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mt: 3,
                                }}
                            >
                                <Button
                                    type="button"
                                    onClick={() =>
                                        navigate("/v1/recruiter/recruiter-profile")
                                    }
                                    variant="outlined"
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        backgroundColor: "white",
                                        borderColor: "primary.main",
                                        color: "text.secondary",
                                        "&:hover": {
                                            backgroundColor: "text.secondary",
                                        },
                                    }}
                                >
                                    <Typography
                                        component="h2"
                                        variant="body1"
                                        sx={{
                                            alignSelf: "flex-start",
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            textTransform: "none",
                                            color: "primary.main",
                                            padding: "2px",
                                        }}
                                    >
                                        Back
                                    </Typography>
                                </Button>

                                <Button
                                    type="button"
                                    variant="standard"
                                    onClick={async () => {
                                        const isValid = await formik.validateForm();
                                        if (Object.keys(isValid).length === 0) {
                                            try {
                                                await formik.submitForm();
                                                
                                                navigate("/v1/recruiter/preview-and-submit", {
                                                    state: { formikValues: formik.values },
                                                });
                                            } catch (error) {
                                                console.error("Error submitting form:", error);
                                            }
                                        } else {
                                            // Mark all fields as touched to display errors
                                            formik.setTouched({
                                                company_profile: {
                                                    company_name: true,
                                                    // company_slogan: true,
                                                    company_location: true,
                                                    company_size: true,
                                                    company_domain: true,
                                                    company_website: true,
                                                },
                                            });
                                        }
                                    }}
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        backgroundColor: "secondary.main",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor: "secondary.main",
                                        },
                                    }}
                                >
                                    <Typography
                                        component="h2"
                                        variant="body1"
                                        sx={{
                                            alignSelf: "flex-start",
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            textTransform: "none",
                                            color: "primary.main",
                                            padding: "2px",
                                        }}
                                    >
                                        Next: Preview and submit
                                    </Typography>
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
       
              </Grid>
            </Grid>
          </Container>
        </Box>
        </>
        
    );
};

export default CompanyProfile;
