// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// Create a function to get the language from localStorage or use a default value
export const getLanguage = () => {
  const storedLanguage =
    typeof localStorage !== "undefined" && localStorage.getItem("i18nextLng");
  return storedLanguage || "en"; // Default to "en" if not found in localStorage
};

// Create a function to set the language in localStorage
const setLanguage = (language) => {
  typeof localStorage !== "undefined" &&
    localStorage.setItem("i18nextLng", language);
};

// Create a function to change the language

export const changeLanguage = (language) => {
  i18n.changeLanguage(language);
  setLanguage(language);
};

i18n
  .use(initReactI18next) // Initializes react-i18next
  .init({
    resources: {
      en: {
        translation: require("./locales/en/translation.json"),
      },
      sp: {
        translation: require("./locales/sp/translation.json"),
      },
    },
    lng: getLanguage(), // Set the language from localStorage
    fallbackLng: "en", // Fallback to the default language
    // cha
    interpolation: {
      escapeValue: false, // React escapes HTML by default, so disable it
    },
  });

// Add an event listener to watch for changes in localStorage
// window not defined error fix

export default i18n;
