import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Button,
    Grid,
    FormControl,
    TextField,
    InputLabel,
    FormControlLabel, Radio, RadioGroup, Divider,Container
} from "@mui/material";
import { Formik, Form } from "formik";
import { OnBoardingApi ,UploadImageApi} from "../../../../apis";
import AddIcon from '@mui/icons-material/Add';
import * as yup from "yup";
import UploadImage from "../../../../components/GlobalComponent/UploadImage";






const AboutYou = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);

    const handleProfilePhotoChange = (event) => {
        const file = event.target.files[0];
        setProfilePhoto(file);
        setProfilePhotoPreview(URL.createObjectURL(file));
    };
    
    const handleProfilePhotoUpload = async () => {
        if (profilePhoto) {
            try {
                const formData = new FormData();
                formData.append("file", profilePhoto);
    
                const res = await UploadImageApi.uploadImage(formData);
                return res.data.data.name; // Return only the name of the uploaded photo
            } catch (error) {
                console.error("Error uploading profile photo:", error);
                throw error;
            }
        }
    };
    const AboutYouForm = ({ formik }) => {
        const { values, handleChange } = formik;
 
        const [loading, setLoading] = useState(true);
    
        useEffect(() => {
            const fetchOnBoardedUserData = async () => {
              try {
                const res = await OnBoardingApi.getOnBoarding();
                function multiParse(input) {
                    let result = input;
                    try {
                      while (typeof result === 'string') {
                        result = JSON.parse(result);
                      }
                    } catch (e) {
                      console.error('Error parsing input:', e);
                    }
                    return result;
                  }
                if (res.data && res.data.profile) {
        
            
                    const parsedEmployementType = multiParse(res?.data?.profile?.employment_type || '{}');
                    const salaryRange = multiParse(res?.data?.profile?.salary_range || '{}');
                    const workExperience = multiParse(res?.data?.profile?.work_experience || '[]');
                    const education = multiParse(JSON.stringify(res?.data?.profile?.education) || '[]');
                    const languages =multiParse(JSON.stringify(res?.data?.profile?.languages) || '[]');
                
                  formik.setValues({
                    ...formik.values,
                    
                        preferences: {
                            professional_area: res?.data?.profile?.professional_area || '',
                            total_years_experience: res?.data?.profile.total_years_experience || '',
                            top_specialities:res?.data?.profile.top_specialities|| "",
                              experienceYears: res?.data?.profile?.experienceYears || {},
                            employment_type: {
                              fullTime: parsedEmployementType?.fullTime || false,
                              partTime: parsedEmployementType?.partTime || false,
                            },
                            job_search_status: res?.data?.profile?.job_search_status || '',
                            remote_work: res?.data?.profile?.remote_work || "",
                            relocation: res?.data?.profile?.relocation || "",
                          },
                          about_you: {
                            resume_url: res?.data?.profile.resume_url || '',
                            profile_photo_url: res?.data?.profile?.profile_photo_url || '',
                            full_name: res?.data?.profile?.full_name || '',
                            phone_number: res?.data?.profile?.phone_number || '',
                            whatsapp_number: res?.data?.profile?.whatsapp_number || '',
                            current_city: res?.data?.profile?.current_city || '',
                            salary_range: {
                              from: salaryRange?.from || '',
                              to: salaryRange?.to || '',
                            },
                            linkedin_url: res?.data?.profile?.linkedin_url || '',
                            personal_blog_url: res?.data?.profile?.personal_blog_url || '',
                          },
                          background: {
              
                            work_experience: workExperience?.length > 0 ? workExperience : [
                              {
                                company_name: workExperience?.company_name || '',
                                job_title: workExperience?.job_title || '',
                                is_current: workExperience?.is_current || false,
                                start: workExperience?.start || '',
                                end: workExperience?.end || '',
                                description: workExperience?.description || '',
                              }
                            ],
                            education: education?.length > 0 ? education : [
                              {
                                university_or_collage: education?.university_or_collage || '',
                                graduation_year: education?.graduation_year || '',
                                field_of_study: education?.field_of_study || '',
                                degree: education?.degree || '',
                              }
                            ],
                            languages: languages?.length > 0 ? languages : [
                              {
                                language: languages?.language || '',
                                language_level: languages?.language_level || '',
                              }
                            ],
                            objective: res?.data?.profile?.objective || '',
                            achievements: res?.data?.profile?.achievements || '',
                            personal_characteristics: res?.data?.profile?.personal_characteristics || '',
                          },
        
                  });
        
        
                } else {
                  console.log("No data available in the database");
                }
                setLoading(false);
              } catch (error) {
                console.error("Error fetching on-boarded user data:", error);
                setLoading(false);
              }
            };
        
            fetchOnBoardedUserData();
          }, []);
        const handleInputSalaryChange = (event) => {
            const { name, value } = event.target;
            let formattedValue = value.replace(/,/g, ''); // Remove existing commas
            formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas to the number
            formik.setFieldValue(name, formattedValue);
        };
        
       
        return (
            
            <div>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Personal Information
                    </Typography>
    
                    <label htmlFor="resume-upload">
                        <Button
                            variant="outlined"
                            component="span"
                            sx={{ textTransform: "none", color: "black" }}
                        >
                            Upload Resume
                        </Button>
                    </label>
                </Box>
    
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start', // Align to the left
                    }}
                >
                   <Grid container spacing={2}>
                            <Grid item>
                                <Box
                                    sx={{
                                        width: "100px",
                                        height: "100px",
                                        border: "1px dotted black",
                                        borderRadius: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "15px",
    
                                    }}
                                >
                                 
                                    <Button>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="profile-photo-upload"
                                        onChange={handleProfilePhotoChange}
                                    />
                                    <label htmlFor="profile-photo-upload">
                                        <Button component="span" sx={{
                                            '&:hover': {
                                                backgroundColor: 'transparent', // Set the background color to transparent on hover
                                            },
                                        }}>
                                            {profilePhotoPreview ? (
                                                <UploadImage
                                                    imageUrl={profilePhotoPreview}
                                                    altText="Profile Image"
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        borderRadius: "5px",
                                                    }}
                                                />
                                            ) : values.about_you.profile_photo_url ? (
                                                <UploadImage
                                                    imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${values.about_you.profile_photo_url}`}
                                                    altText={values.about_you.profile_photo_url}
                                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                   
                                                />
                                            ) : (
                                                <AddIcon sx={{ fontSize: 30, color: "black" }} />
                                            )}
                                        </Button>
                                    </label>
                                </Button>
                                 
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                                    Profile Photo
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ mr: 0, textDecoration: "none", color: "gray", fontSize: "11px" }}
                                >
                                    Maximum file size is up to 10MB
                                </Typography>
                            </Grid>
                        </Grid>
                </Box>
    
    
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                Name & Surname *
                            </InputLabel>
                            <TextField
                                name="about_you.full_name"
                                value={values.about_you.full_name}
                                onChange={handleChange}
                                placeholder="Enter Name"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: false,
                                    style: {
                                        fontSize: '14px',
                                        color: 'gray'
                                    }
                                }}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            {formik.errors.about_you && (
                                <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                    {formik.errors.about_you.full_name}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                Phone No *
                            </InputLabel>
                            <TextField
                                name="about_you.phone_number"
                                value={values.about_you.phone_number}
                                onChange={handleChange}
                                placeholder="Enter phone no"
                                InputLabelProps={{
                                    shrink: false,
                                    style: {
                                        fontSize: '14px',
                                        color: 'gray'
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            {formik.errors.about_you && (
                                <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                    {formik.errors.about_you.phone_number}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Box>
    
                <Box mt={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                WhatsApp or Telegram 
                            </InputLabel>
                            <TextField
    
                                name="about_you.whatsapp_number"
                                value={values.about_you.whatsapp_number}
                                placeholder="Enter WhatsApp no"
                                onChange={handleChange}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: false,
                                    style: {
                                        fontSize: '14px',
                                        color: 'gray'
                                    }
                                }}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                              {formik.errors.about_you && (
                                <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                    {formik.errors.about_you.whatsapp_number}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                Your Current City *
                            </InputLabel>
                            <TextField
    
                                name="about_you.current_city"
                                value={values.about_you.current_city}
                                onChange={handleChange}
                                placeholder="Enter City"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: false,
                                    style: {
                                        fontSize: '14px',
                                        color: 'gray'
                                    }
                                }}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                             {formik.errors.about_you && (
                                <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                    {formik.errors.about_you.current_city}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Box>
    
    
                <Box>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Salary Expectation
                    </Typography>
                    {/* <Grid item xs={12}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                                Compensation period
                            </InputLabel>
                            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column' }}>
                                <RadioGroup
                                    aria-label="options"
                                    name="options"
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                    sx={{ flexDirection: 'row' }}
                                >
                                    <FormControlLabel
                                        value="Per annum (p/a)"
                                        control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                                        label="Per annum (p/a)"
                                        sx={{ marginRight: '20px' }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid> */}
                  <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                            Specify the salary range (annually) *
                        </InputLabel>
                        <TextField
                            fullWidth
                            placeholder="From"
                            name="about_you.salary_range.from"
                            value={values.about_you.salary_range.from}
                            onChange={handleInputSalaryChange}
                           
                        />
                        
                        {formik.touched.about_you?.salary_range?.from && formik.errors.about_you?.salary_range?.from && (
                        <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                            {formik.errors.about_you.salary_range.from}
                        </Typography>
                    )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                            To *
                        </InputLabel>
                        <TextField
                            fullWidth
                            InputLabelProps={{
                                shrink: false,
                                style: { fontSize: '14px', color: 'gray' }
                            }}
                
                            placeholder="To"
                            name="about_you.salary_range.to"
                            value={values.about_you.salary_range.
                                to}
                            onChange={handleInputSalaryChange}
                        />
                        {formik.touched.about_you?.salary_range?.to && formik.errors.about_you?.salary_range?.to && (
                        <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                            {formik.errors.about_you.salary_range.to}
                        </Typography>
                    )}
                    </Grid>
                </Grid>
            </Box>
                </Box>
    
                <Box>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px', }}>
                        Links
                    </Typography>
                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                        LinkedIn Profile URL
                    </Typography>
                    <TextField
                        name="about_you.linkedin_url"
                        value={values.about_you.linkedin_url}
                        onChange={handleChange}
                        placeholder="LinkedIn Profile URL"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: false,
                            style: {
                                fontSize: '14px',
                                color: 'gray'
                            }
                        }}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                        Personal Blog URL
                    </Typography>
                    <TextField
                        name="about_you.personal_blog_url"
                        value={values.about_you.personal_blog_url}
                        onChange={handleChange}
                        placeholder="Personal Blog URL"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: false,
                            style: {
                                fontSize: '14px',
                                color: 'gray'
                            }
                        }}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
    
                </Box>
            </div>
        );
    };
    const submitForm = async (values) => {
        try {
            // Convert top_specialities to JSON string
          
            const updatedValues = {
                ...values,
                
                preferences: {
                    ...values.preferences,
                
                employment_type: values.preferences?.employment_type ? JSON.stringify(values.preferences.employment_type) : '{}',
                    salary_range: values.about_you?.salary_range ?JSON.stringify(values.about_you.salary_range): '{}'
                }
              
            };
            const profilePhotoName = await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
            if (profilePhotoName) {
                values.about_you.profile_photo_url = profilePhotoName; // Set profile_photo_url to the photo name
            }
            
            const res = await OnBoardingApi.OnBoarding(updatedValues);
      
        } catch (err) {
            console.error(err);
        }
    };
    const [formikValues, setFormikValues] = useState({
        about_you: {
            resume_url: "",
            profile_photo_url: "",
            full_name: "",
            phone_number: "",
            whatsapp_number: "",
            salary_range: {
                from:  '',
                to:  '',
              },
            current_city: "",
            linkedin_url: "",
            personal_blog_url: ""
        },

    });
    const validationSchema = yup.object().shape({
        about_you: yup.object().shape({
            full_name: yup.string().required('Name & Surname is required'),
            phone_number: yup.string().required('Phone No is required'),
            whatsapp_number: yup.string().required('WhatsApp or Telegram is required'),
            current_city: yup.string().required('Your Current City is required'),
            salary_range: yup.object().shape({
                from: yup.string().required('Salary is required'),
                to: yup.string()
                    .required('Salary is Required')
                  
            }),
        
            // linkedin_url: yup.string().url('Invalid URL').required('LinkedIn Profile URL is required'),
            // github_url: yup.string().url('Invalid URL').required('Github Profile URL is required'),
        }),
    });
    return (
        <>

        <Box sx={{ backgroundColor: 'white', minHeight: '100vh',padding:0 }}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
              <Box sx={{mx: {lg:"150px"}}}>
                <Formik
                    initialValues={formikValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        submitForm(values);
                      
                    }}
                    validateOnChange={false}
                    validateOnBlur={false} // Disable validation on blur to prevent premature error display
                >
                    {(formik) => (
                        <Form>

                            <AboutYouForm formik={formik} />
                            <Divider sx={{ my: 2 }} />

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mt: 3,
                                }}
                            >
                                <Button
                                    type="button"
                                    onClick={() => navigate("/v1/employee/preferences")}
                                    variant="outlined"
                                    sx={{
                                        mt: 3, mb: 2, backgroundColor: 'white', borderColor: 'primary.main', color: 'text.secondary', '&:hover': {
                                            backgroundColor: 'text.secondary'
                                        }
                                    }}
                                >
                                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", }}>
                                        Back
                                    </Typography>
                                </Button>

                                <Button
                                    type="submit"
                                    variant="standard"
                                    // onClick={() => navigate("/v1/employee/background")}
                                    // onClick={() =>navigate("/v1/employee/background", { state: { formikValues: formik.values } })}
                                    onClick={async () => {
                                        const isValid = await formik.validateForm();
                                        if (Object.keys(isValid).length === 0) {
                                            try {
                                                await formik.submitForm();
                                            
                                                navigate("/v1/employee/background", {
                                                    state: { formikValues: formik.values },
                                                });

                                            } catch (error) {
                                                console.error("Error submitting form:", error);
                                            }
                                        }
                                        else {
                                            // Mark all fields as touched to display errors
                                            formik.setTouched({
                                                about_you: {
                                                    full_name: true,
                                                    phone_number: true,
                                                    whatsapp_number: true,
                                                    current_city: true,
                                                    linkedin_url: true,
                                                    personal_blog_url: true,

                                                },
                                            });
                                        }
                                    }}
                                    sx={{
                                        mt: 3, mb: 2, backgroundColor: 'secondary.main', color: 'white', '&:hover': {
                                            backgroundColor: 'secondary.main'
                                        }
                                    }}
                                >
                                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                                        Next: Background
                                    </Typography>
                                </Button>
                            </Box>

                        </Form>
                    )}
                </Formik>
            </Box>

   
              </Grid>
            </Grid>
          </Container>
        </Box>
        </>
       
    );
};

export default AboutYou;
