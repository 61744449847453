import React, { useState,useEffect } from 'react';
import { Card, CardActions, CardContent, Button, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatRounded';
import UploadImage from '../GlobalComponent/UploadImage';
import CurrencyFormatter from '../GlobalComponent/CurrencyFormater';
import { ChatApi } from '../../apis';
import { use } from 'i18next';


export default function JobCard({ offer, offerId, isAccepted, jobId }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
 

  const handleChat = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const response = await ChatApi.createConversation({
      receiver_id: offer.profile.user_id,
      sender_id: user.id,
      job_id: jobId,
    });
    let userRole = JSON.parse(localStorage.getItem('user')).role;
    if (userRole.name === 'recruiter') {
    navigate(`/v1/recruiter/chat?conversationId=${response.data.data.conversation_id}`);
    }
    else {
      navigate(`/v1/employee/chat?conversationId=${response.data.data.conversation_id}`);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <Card sx={{
      display: { sm: 'flex' }, justifyContent: 'space-between', alignItems: 'center', padding: 2, backgroundColor: 'white',
      marginBottom: 2, mx: "auto"
  }}>
      <Box sx={{ display: { sm: 'flex' }, alignItems: 'center', gap: 2, backgroundColor: 'white' }}>
          <Box
              sx={{
                  width: { xs: "100%", sm: 80, md: 110 },
                  height: { xs: 200, sm: 80, md: 110 },
                  backgroundColor: 'primary.main',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'lightgray',
                  m: 1,
              }}
          >
          {offer.profile && offer.profile.profile_photo_url ? (
            <UploadImage
              imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${offer.profile.profile_photo_url}`}
              altText={offer.profile.profile_photo_url}
            />
          ) : (
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              Upload
            </Typography>
          )}
        </Box>

        <CardContent sx={{flex:  {md:'1 0 auto'} }}>
          <Typography sx={{ fontSize: { sm: "16px", md: "22px" } }} variant="h6">{offer.profile.full_name || 'N/A'}</Typography>
          <Typography variant="body2" color="text.primary" sx={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
          <LocationOnIcon sx={{mt:{xs:-1.5,sm:0 }}} /> {offer.profile?.company_location || 'N/A'} • Full time • <CurrencyFormatter value={offer.profile?.salaryRange?.from || 'N/A'} /> - <CurrencyFormatter value={offer.profile?.salaryRange?.to || 'N/A'} />
          </Typography>
        </CardContent>
      </Box>
      <CardActions sx={{ justifyContent: 'flex-end', flexDirection: 'column',}}>
        {isAccepted && (
          <IconButton
            sx={{
              mt: 2,
              mb: 2,
              ml: -1,
              width: "50px",
              backgroundColor: 'white',
              color: 'green',
              '&:hover': {
                backgroundColor: 'white',
                color: 'green',
              },
              '&:focus': {
                outline: '2px solid white',
                backgroundColor: 'white',
              },
              '&:active': {
                backgroundColor: 'white',
                color: 'green',
              }
            }}
            onClick={handleChat}
          >
            <ChatBubbleOutlineIcon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
}
