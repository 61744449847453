import React from 'react';
import { Container, Grid, Typography, Box, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import founderImage from '../../assets/images/tristan.jpg'; // Use the actual path to your founder image

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f8f8f8', // Light grey background
    padding: theme.spacing(8, 4),
    alignContent: "center",
    justifyContent: "center",
  },
  header: {
    textAlign: 'left',
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: '3rem',
    fontWeight: '400',
    marginBottom: theme.spacing(2),
  },
  content: {
    fontSize: '1rem',
    color: '#333',
    fontWeight: "500 !important"
  },
  boldText: {
    fontWeight: 'bold',
  },
  imageContainer: {
    display: 'flex',
    border: '1px dotted transparent',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '35px',
    overflow: 'hidden',
    margin: '15%',
  
  
  },
  avatar: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  founderName: {
    fontSize: '1.6rem',
    fontWeight: '400',
    marginTop: theme.spacing(2),
  },
  founderRole: {
    fontSize: '12px',
    color: 'black',
    fontWeight: "800 !important"
  },
}));

const DeQueSurgeElProyecto = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isExtraSmallScreen = useMediaQuery('(max-width:320px)'); // Custom media query
  // Define sizes based on media queries
  const size = isExtraSmallScreen ? 170 : isSmallScreen ? 200 : isMediumScreen ? 200 : 300;

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={6} className={classes.header}>
            <Box sx={{ alignContent: "center", justifyContent: "center" }}>
              <Typography>
                <span className={classes.title}>¿De qué surge el <br /> proyecto?</span>
              </Typography>
              <Box
                className={classes.imageContainer}
                sx={{
                  width: `${size}px`,
                  height: `${size}px`,
                  
                }}
              >
                <img
                  src={founderImage}
                  alt="Tristán Vidal"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    objectFit: "cover",
                    objectPosition:"top"
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <br />
            <Typography>
              <span className={classes.content}>
                Tras una situación de desempleo comprobé las carencias que <br /> 
                presentaba la industria del reclutamiento de talento.<br />
                Considerando que tenía un buen perfil enfocado en mi área, el <br /> 
                marketing y la comunicación, me resultaba difícil destacar<br />  
                entre los cientos y cientos de aplicaciones que había en 
                cada oferta de empleo que veía en otros portales.
                Incluso aunque hubiesen transcurrido pocos minutos desde 
                la creación de esta, y es que:
                ¿quién no ha visto una oferta que sabe que no tiene el perfil 
                y ha aplicado por si se da la casualidad?<br />
                Pues bien, Hirio ha nacido para ensalzar el perfil profesional<br />  
                de los usuarios y facilitar la búsqueda de candidatos a 
                las empresas, haciendo que sea un proceso rápido y ágil.
                Sinceramente, espero que este proyecto ayude a mejorar la<br />
                oferta y competitividad del mundo laboral.
              </span>
            </Typography>

            <br />
            <br />
            <br />
            <br />
            <Typography>
              <span className={classes.founderName}>Tristán Vidal</span>
            </Typography>
            <Typography className={classes.founderRole}>FUNDADOR</Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DeQueSurgeElProyecto;
