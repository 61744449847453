import React, { useState, useContext, useEffect } from 'react';
import { JobPostApi } from '../../apis';
import { Typography, Divider, Container, Grid, Card, CardContent, Avatar, IconButton, Tab, Tabs, Box, CardActions, Button, DialogActions, DialogTitle, DialogContent, Dialog, InputLabel, TextField, FormControl, Select } from '@mui/material';
import JobContext from '../../context/JobContext';
import JobCard from './JobCard';


const JobOffers = () => {
    const {  jobDataArray, setJobDataArray, pendingJobData, setPendingJobData,acceptedJobData,setAcceptedJobData, rejectedJobData, setRejectedJobData, loading, setLoading, removeJobPost } = useContext(JobContext);
    const [tabValue, setTabValue] = React.useState(0);
    const [openApplyForm, setOpenApplyForm] = useState(false);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [anchorEl, setAnchorEl] = useState(null);

    const [selectedJobId, setSelectedJobId] = useState(null);

    const [jobs, setJobs] = useState([]);
    const fetchJobPosts = async () => {
        setLoading(true);
        try {
            const res = await JobPostApi.getUserJobsOffer();
            if (res && res.data && res.data.data) {
                const parsedJobPosts = res.data.data.map(job => ({
                    ...job,
                    jobDetails: parseJSON(job.jobDetails),
                    requirements: parseJSON(job.requirements),
                    description: parseJSON(job.description),
                }));
                console.log('parsedJobPosts', parsedJobPosts);
                
                const pendingJobs = parsedJobPosts.filter(job => job.jobOfferStatus === 'Pending');
                setPendingJobData(pendingJobs);
              
                const acceptedJobs = parsedJobPosts.filter(job => job.jobOfferStatus === 'Accepted');
                setAcceptedJobData(acceptedJobs);

                const rejectedJobs = parsedJobPosts.filter(job => job.jobOfferStatus === 'Rejected');
                setRejectedJobData(rejectedJobs);
              
            } else {
                console.error('Error fetching job posts:', res ? res.message : 'No response received');
            }
        } catch (error) {
            console.error('Error fetching job posts:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobPosts();
    }, [setJobDataArray, setLoading]);


    // Helper function to safely parse JSON
    const parseJSON = (jsonString) => {
        try {
            // Check if jsonString is already an object
            if (typeof jsonString === 'object') {
                return jsonString; // Return as-is if already an object
            }
            return jsonString ? JSON.parse(jsonString) : {}; // Parse JSON if jsonString is not null or undefined
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return {}; // Return empty object if parsing fails
        }
    };

    useEffect(() => {
        setJobDataArray(jobDataArray || []);
    }, [jobDataArray]);

    //   if (loading) {
    //     return <div>
    //          <Loader loading={loading} />
    //     </div>;
    //   }

    const handleMenuItemClick = async (id, action) => {
        setSelectedJobId(id);
        setAnchorEl(null); // Close the menu
    };
    const jobOffers = {
        accepted: [

            // Add more accepted offers
        ],
        pending: [
            // Add pending offers
        ],
        rejected: [
            // Add rejected offers
        ]
    };
    
    console.log('pendingJobData', pendingJobData);


    return (
      <>
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginBottom: '10px', marginTop: "30px" }}>
                Job Offers
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', '& .MuiTabs-flexContainer': { justifyContent: 'space-between' }, minWidth:"240px"}} >
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="job offer tabs"
                    indicatorColor="secondary"
                >
                     <Tab
                        sx={{
                            fontSize: { xs: "10px", sm: "12px",md:"14px"},
                            color: tabValue === 0 ? 'primary.main' : 'black',
                            width: '25%',
                            fontWeight: tabValue === 0 ? 'bold' : 'normal'
                        }}
                        label={`Pending (${pendingJobData.length})`}
                    />
                    <Tab
                        sx={{
                            fontSize: { xs: "10px", sm: "12px",md:"14px"},
                            color: tabValue === 1 ? 'primary.main' : 'black',
                            width: '25%',
                            fontWeight: tabValue === 1 ? 'bold' : 'normal'
                        }}
                        label={`Accepted (${acceptedJobData.length})`}
                    />
                   
                    <Tab
                        sx={{
                            fontSize: { xs: "10px", sm: "12px",md:"14px"},
                            color: tabValue === 2 ? 'primary.main' : 'black',
                            width: '25%',
                            fontWeight: tabValue === 2 ? 'bold' : 'normal'
                        }}
                        label={`Rejected (${rejectedJobData.length})`}
                    />
                </Tabs>
            </Box>
            <div>
                {tabValue === 0 && (
                   <Box sx={{ pt: 2 }}>
                   {pendingJobData.length > 0 ? (
                       pendingJobData.map(offer => (
                           <JobCard key={offer.id} offer={offer.jobDetails} offerId={offer} isPending={true}  isAccepted={false} isRejected={false} />
                       ))
                   ) : (
                       <Typography sx={{ ml:{ xs:2,sm: 11 }}}>No job post available in pending</Typography>
                   )} 
                </Box>
                )}
                {tabValue === 1 && (
                   <Box sx={{ pt: 2 }}>
                   {acceptedJobData.length > 0 ? (
                       acceptedJobData.map(offer => (
                           <JobCard key={offer.id} offer={offer.jobDetails} offerId={offer} isPending={false}  isAccepted={true} isRejected={true} />
                       ))
                   ) : (
                       <Typography sx={{ ml:{ md: 11 }}}>No job post available in accepted</Typography>
                   )}
                </Box>
                )}
                {tabValue === 2 && (
                      <Box sx={{ pt: 2 }}>
                      {rejectedJobData.length > 0 ? (
                          rejectedJobData.map(offer => (
                              <JobCard key={offer.id} offer={offer.jobDetails} offerId={offer} isPending={false} isAccepted={false} isRejected={true} />
                          ))
                      ) : (
                          <Typography sx={{ ml: 11 }}>No job post available in rejected</Typography>
                      )}
                  </Box>
                )}
                
            </div>


        </>
    );
};

export default JobOffers;
