import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Box,useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  card: {
    border: `1px solid black`,
    borderRadius: '30px !important',
    padding: theme.spacing(2),
    textAlign: 'left',
    height: "85%"
  },
  stepNumber: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#8EFFC2',
  
  },
  stepTitle: {
    
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: 'black', // Setting title color to black
    display: 'block', // Ensure each line is on a new line
  },
  stepDescription: {
 
    marginTop: theme.spacing(3),
    fontSize: '1rem',
    color: '#333',
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  headerTitle: {
    fontWeight: 'normal',
    fontSize: '3rem !important',
    
  },
  headerHighlight: {
    fontWeight: 'bold',
  },
}));

const steps = [
  {
    number: '1.',
    title: 'CRÉATE UNA \nCUENTA',
    description: 'Con un sólo clic te harás una cuenta, y es que es completamente gratis, rápido y sencillo.',
  },
  {
    number: '2.',
    title: 'COMPLETA EL \n PERFIL',
    description: 'Añade toda tu información y experiencia laboral para facilitar que te encuentren.',
  },
  {
    number: '3.',
    title: 'EMPIEZA A \n RECIBIR OFERTAS',
    description: 'Tu perfil empezará a hacer match con ofertas de empleo que buscan profesionales como tú.',
  },
  {
    number: '4.',
    title: 'ACEPTA EL MEJOR \n TRABAJO',
    description: 'Compara entre las ofertas que te lleguen y selecciona la que mejor se ajuste a lo que buscas.',
  },
];

const Steps = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h4" sx={{ fontSize: isSmallScreen ? '1rem' : '2.5rem' }}className={classes.headerTitle}>
          Así de fácil
        </Typography>
        <Typography variant="h4" sx={{ fontSize: isSmallScreen ? '1rem' : '2.5rem' }} className={classes.headerTitle}>
          funciona <span className={classes.headerHighlight}>Hirio</span>
        </Typography>
      </Box>
      <br />
      <br />
      <Grid container spacing={3}>
        {steps.map((step, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Card className={classes.card}>
              <CardContent>
              <Typography ><span className={classes.stepNumber}>{step.number}</span></Typography>
                {step.title.split('\n').map((line, i) => (
                  <Typography ><span key={i} className={classes.stepTitle}>{line}</span></Typography> 
                ))}
              
                <Typography className={classes.stepDescription}>{step.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
    </Container>
  );
};

export default Steps;
