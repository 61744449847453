import React from 'react';
import { Grid, Container, Box } from '@mui/material';
import NavBar from '../../../../components/Global/NavBar';
import MyProfile from '../../../../components/Profile/employeeProfile';
import Footer from '../../../../components/Global/Footer';

const MyProfilePage = () => {
  return (
    <>
      <NavBar />
      <Box sx={{ backgroundColor: '#F5F5F5', minHeight: '100vh',mt:-6 }}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <MyProfile />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default MyProfilePage;
