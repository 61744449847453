import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { OnBoardingApi } from "../../../../apis";
import AddIcon from '@mui/icons-material/Add';
import * as yup from "yup";
import dayjs from 'dayjs';

import {
    Box,
    Typography,
    Button,
    Grid,
    FormControl,
    TextField,
    InputLabel,
    MenuItem,
    Select,
    Checkbox, FormControlLabel,
    Divider,Container
} from "@mui/material";
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    datePicker: {
        width: '100%', // Default width

    },
}));
const submitForm = async (values) => {
    try {
        // Convert top_specialities to JSON string
        const updatedValues = {
            ...values,
            preferences: {
                ...values.preferences,
            
                employment_type: values.preferences?.employment_type ? JSON.stringify(values.preferences.employment_type) : '{}'

            }
        };

        const res = await OnBoardingApi.OnBoarding(updatedValues);

    } catch (err) {
        console.error(err);
    }
};

const BackgroundForm = ({ formik }) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchOnBoardedUserData = async () => {
            try {
                const res = await OnBoardingApi.getOnBoarding();

                function multiParse(input) {
                    let result = input;
                    try {
                        while (typeof result === 'string') {
                            result = JSON.parse(result);
                        }
                    } catch (e) {
                        console.error('Error parsing input:', e);
                    }
                    return result;
                }


                if (res.data && res.data.profile) {
                    const parsedEmployementType = multiParse(res?.data?.profile?.employment_type || '{}');
                    const salaryRange = multiParse(res?.data?.profile?.salary_range || '{}');
                    const workExperience = multiParse(JSON.parse(res?.data?.profile?.work_experience) || '[]');
                    const education = multiParse(JSON.stringify(res?.data?.profile?.education) || '[]');
                    const languages = multiParse(JSON.stringify(res?.data?.profile?.languages) || '[]');


                    formik.setValues({
                        ...formik.values,
                        preferences: {
                            professional_area: res?.data?.profile?.professional_area || '',
                            total_years_experience: res?.data?.profile.total_years_experience || '',
                            top_specialities:res?.data?.profile.top_specialities|| "",
                              experienceYears: res?.data?.profile?.experienceYears || {},
                            employment_type: {
                                fullTime: parsedEmployementType?.fullTime || false,
                                partTime: parsedEmployementType?.partTime || false,
                            },
                            job_search_status: res?.data?.profile?.job_search_status || '',
                            remote_work: res?.data?.profile?.remote_work || "",
                            relocation: res?.data?.profile?.relocation || "",
                        },
                        about_you: {
                            resume_url: res?.data?.profile.resume_url || '',
                            profile_photo_url: res?.data?.profile?.profile_photo_url || '',
                            full_name: res?.data?.profile?.full_name || '',
                            phone_number: res?.data?.profile?.phone_number || '',
                            whatsapp_number: res?.data?.profile?.whatsapp_number || '',
                            current_city: res?.data?.profile?.current_city || '',
                            salary_range: {
                                from: salaryRange?.from || '',
                                to: salaryRange?.to || '',
                            },
                            linkedin_url: res?.data?.profile?.linkedin_url || '',
                            personal_blog_url: res?.data?.profile?.personal_blog_url || '',
                        },
                        background: {

                            work_experience: workExperience?.length > 0 ? workExperience : [
                                {
                                    company_name: workExperience?.company_name || '',
                                    job_title: workExperience?.job_title || '',
                                    is_current: workExperience?.is_current || false,
                                    start: workExperience?.start || '',
                                    end: workExperience?.end || '',
                                    description: workExperience?.description || '',
                                }
                            ],
                            education: education?.length > 0 ? education : [
                                {
                                    university_or_collage: education?.university_or_collage || '',
                                    graduation_year: education?.graduation_year || '',
                                    field_of_study: education?.field_of_study || '',
                                    degree: education?.degree || '',
                                }
                            ],
                            languages: languages?.length > 0 ? languages : [
                                {
                                    language: languages?.language || '',
                                    language_level: languages?.language_level || '',
                                }
                            ],
                            objective: res?.data?.profile?.objective || '',
                            achievements: res?.data?.profile?.achievements || '',
                            personal_characteristics: res?.data?.profile?.personal_characteristics || '',
                        },


                    });


                } else {
                    console.log("No data available in the database");
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching on-boarded user data:", error);
                setLoading(false);
            }
        };

        fetchOnBoardedUserData();
    }, []);
    const navigate = useNavigate();
    const { values, handleChange } = formik;
    const classes = useStyles();


    const [workExperiences, setWorkExperiences] = useState(values.work_experience || [
        {

            company_name: "",
            job_title: "",
            is_current: false,
            start: null, // Initialize with null or a default date object
            end: null, // Initialize end date with null or a default date object
            description: "",
        }
    ]);
    const [addedWorkExperiences, setAddedWorkExperiences] = useState([false]);

    const handleWorkExperienceChange = (index, field, value) => {
        const updatedExperiences = [...workExperiences];
        updatedExperiences[index][field] = value;
        setWorkExperiences(updatedExperiences);
        formik.setFieldValue(`background.work_experience[${index}].${field}`, value);
    };

    const handleDateChange = (date, dateType, index) => {
        const formattedDate = dayjs(date).format("MMMM DD, YYYY hh:mm A");

        // Make sure background.work_experience is initialized properly
        if (!formik.values.background.work_experience) {
            formik.setFieldValue('background.work_experience', []);
        }

        // Make a copy of the array of work experiences
        const updatedWorkExperiences = [...formik.values.background.work_experience];

        // Ensure index is within bounds
        if (index >= 0 && index < updatedWorkExperiences.length) {
            // Update the start date of the specific work experience entry
            updatedWorkExperiences[index] = {
                ...updatedWorkExperiences[index],
                start: formattedDate,
            };

            // Set the updated value (formatted date) back into Formik's form state
            formik.setFieldValue(`background.work_experience`, updatedWorkExperiences);


        } else {
            console.error(`Invalid index ${index} for background.work_experience`);
        }
    };


    const handleStartDateChange = (index, date) => {
        const updatedWorkExperiences = [...values.background.work_experience];
        updatedWorkExperiences[index].start = date;
        formik.setFieldValue(`background.work_experience[${index}].start`, date);
    };

    const handleEndDateChange = (index, date) => {
        const updatedWorkExperiences = [...values.background.work_experience];
        updatedWorkExperiences[index].end = date;
        formik.setFieldValue(`background.work_experience[${index}].end`, date);
    };
    const handleAddWorkExperience = () => {
        setWorkExperiences([
            ...workExperiences,
            {
                company_name: "",
                job_title: "",
                is_current: false,
                start: null, // Initialize with null or a default date object
                end: null, // Initialize end date with null or a default date object
                description: "",
            }
        ]);
        setAddedWorkExperiences([...addedWorkExperiences, true]);
        formik.setFieldValue(`background.work_experience[${workExperiences.length}].company_name`, "");
        formik.setFieldValue(`background.work_experience[${workExperiences.length}].job_title`, "");
        formik.setFieldValue(`background.work_experience[${workExperiences.length}].is_current`, "");
        formik.setFieldValue(`background.work_experience[${workExperiences.length}].start`, "");
        formik.setFieldValue(`background.work_experience[${workExperiences.length}].end`, "");
        formik.setFieldValue(`background.work_experience[${workExperiences.length}].description`, "");
    };

    const handleRemoveWorkExperience = (index) => {
        const updatedExperiences = [...workExperiences];
        updatedExperiences.splice(index, 1);
        setWorkExperiences(updatedExperiences);
        const updatedAddedExperience = [...addedWorkExperiences];
        updatedAddedExperience.splice(index, 1);
        setAddedWorkExperiences(updatedAddedExperience);
        formik.setFieldValue(`background.work_experience`, updatedExperiences);
    };

    const [languages, setLanguages] = useState(values.languages || [{ language: "", language_level: "" }]);
    const [addedLanguages, setAddedLanguages] = useState([false]);

    const handleLanguageChange = (index, field, value) => {
        const updatedLanguages = [...languages];
        updatedLanguages[index][field] = value;
        setLanguages(updatedLanguages);
        formik.setFieldValue(`background.languages[${index}].${field}`, value);
    };

    const handleAddLanguage = () => {
        setLanguages([...languages, { language: "", language_level: "" }]);
        setAddedLanguages([...addedLanguages, true]);
        formik.setFieldValue(`background.languages[${languages.length}].language`, "");
        formik.setFieldValue(`background.languages[${languages.length}].language_level`, "");
    };

    const handleRemoveLanguage = (index) => {
        const updatedLanguages = [...languages];
        updatedLanguages.splice(index, 1);
        setLanguages(updatedLanguages);

        const updatedAddedLanguages = [...addedLanguages];
        updatedAddedLanguages.splice(index, 1);
        setAddedLanguages(updatedAddedLanguages);

        // Update Formik values when removing language
        formik.setFieldValue(`background.languages`, updatedLanguages);
    };

    const [educations, setEducations] = useState(values.education || [
        {
            university_or_collage: "",
            graduation_year: "",
            field_of_study: "",
            degree: "",
        }
    ]);
    const [addedEducations, setAddedEducations] = useState([false]);

    const handleEducationChange = (index, field, value) => {
        const updatedEducations = [...educations];
        updatedEducations[index][field] = value;
        setEducations(updatedEducations);
        formik.setFieldValue(`background.education[${index}].${field}`, value);


    };

    const handleAddEducation = () => {
        setEducations([...educations, {
            university_or_collage: "",
            graduation_year: "",
            field_of_study: "",
            degree: "",
        }]);
        setAddedEducations([...addedEducations, true]);
        formik.setFieldValue(`background.education[${educations.length}].university_or_collage`, "");
        formik.setFieldValue(`background.education[${educations.length}].graduation_year`, "");
        formik.setFieldValue(`background.education[${educations.length}].field_of_study`, "");
        formik.setFieldValue(`background.education[${educations.length}].degree`, "");
    };

    const handleRemoveEducation = (index) => {
        const updatedEducations = [...educations];
        updatedEducations.splice(index, 1);
        setEducations(updatedEducations);

        const updatedAddedEducations = [...addedEducations];
        updatedAddedEducations.splice(index, 1);
        setAddedEducations(updatedAddedEducations);


        // Update Formik values when removing language
        formik.setFieldValue(`background.education`, updatedEducations);
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();
        // Handle form submission here, e.g., using formik.handleSubmit()

        // Example: formik.handleSubmit();
    };

    return (
        <form onSubmit={handleSubmitForm}>
            <Box>
                <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                    Work Experience *
                </Typography>
                <Box>
                    <Grid container spacing={2}>

                        {formik.values.background.work_experience.map((experience, index) => (
                            <React.Fragment key={index}>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <InputLabel mt={2} sx={{ alignSelf: 'flex-start', fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                        Company Name *
                                    </InputLabel>
                                    <TextField
                                        name={`background.work_experience[${index}].company_name`}
                                        placeholder="Company Name"
                                        variant="outlined"
                                        fullWidth
                                        value={experience.company_name}
                                        onChange={(e) => handleWorkExperienceChange(index, "company_name", e.target.value)}
                                        sx={{ mb: 2 }}
                                    />
                                    {formik.touched.background?.work_experience?.[index]?.company_name && formik.errors.background?.work_experience?.[index]?.company_name ? (
                                        <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                            {formik.errors.background.work_experience[index].company_name}
                                        </Typography>
                                    ) : null}

                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel mt={2} sx={{ alignSelf: 'flex-start', fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                            Company Website *
                                        </InputLabel>
                                        <TextField
                                            placeholder="Company Website"
                                            variant="outlined"
                                            fullWidth
                                            value={experience.companyWebsite}
                                            onChange={(e) => handleWorkExperienceChange(index, "companyWebsite", e.target.value)}
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid> */}

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <InputLabel mt={2} sx={{ alignSelf: 'flex-start', fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                        Job Title *
                                    </InputLabel>
                                    <TextField
                                        name={`background.work_experience[${index}].job_title`}
                                        placeholder="Job Title"
                                        variant="outlined"
                                        fullWidth
                                        value={experience.job_title}
                                        onChange={(e) => handleWorkExperienceChange(index, "job_title", e.target.value)}
                                        sx={{ mb: 2 }}
                                    />
                                    {formik.touched.background?.work_experience?.[index]?.job_title && formik.errors.background?.work_experience?.[index]?.job_title ? (
                                        <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                            {formik.errors.background.work_experience[index].job_title}
                                        </Typography>
                                    ) : null}

                                </Grid>



                                {!experience.is_current && (
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                                            Started
                                        </InputLabel>

                                        <Box sx={{ position: 'relative', py: '20px', mr: 2 }}>
                                            <div className='calendar'>
                                                <Grid item xs={12}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            renderInput={(props) => <TextField {...props} />}
                                                            name={`background.work_experience[${index}].start`}
                                                            value={experience.startedAt}
                                                            onChange={(newValue) => handleDateChange(newValue, "start")}
                                                            inputFormat="MM/dd/yyyy"
                                                            views={['month', 'year']}
                                                            sx={{ width: "100%" }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </div>
                                        </Box>

                                    </Grid>

                                )}
                                <Box>

                                </Box>
                                {!experience.is_current && (

                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                                            Finished
                                        </InputLabel>

                                        <Box sx={{ position: 'relative', py: '20px', mr: 2 }}>
                                            <div className='calendar'>
                                                <Grid item xs={12}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            renderInput={(props) => <TextField {...props} />}
                                                            name={`background.work_experience[${index}].end`}
                                                            value={experience.endedAt}
                                                            onChange={(newValue) => handleDateChange(newValue, "end")}
                                                            inputFormat="MM/dd/yyyy"
                                                            views={['month', 'year']}
                                                            sx={{ width: "100%" }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </div>
                                        </Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{ alignSelf: 'flex-start', color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                                    name={`background.work_experience[${index}].is_current`}
                                                    checked={experience.is_current}
                                                    onChange={(e) => handleWorkExperienceChange(index, "is_current", e.target.checked)}
                                                />
                                            }
                                            label="I am currently working here"
                                        />
                                    </Grid>
                                )}
     {experience.is_current && (
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                                            Started
                                        </InputLabel>

                                        <Box sx={{ position: 'relative', py: '20px', mr: 2 }}>
                                            <div className='calendar'>
                                                <Grid item xs={12}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            renderInput={(props) => <TextField {...props} />}
                                                            name={`background.work_experience[${index}].start`}
                                                            value={experience.startedAt}
                                                            onChange={(newValue) => handleDateChange(newValue, "start")}
                                                            inputFormat="MM/dd/yyyy"
                                                            views={['month', 'year']}
                                                            sx={{ width: "100%" }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </div>
                                        </Box>

                                    </Grid>

                                )}
                                <Box>

                                </Box>
                                {experience.is_current && (

                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                       

                                        <Box sx={{ position: 'relative', py: '20px', ml: 10,mt:4 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{ alignSelf: 'flex-start', color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                                    name={`background.work_experience[${index}].is_current`}
                                                    checked={experience.is_current}
                                                    onChange={(e) => handleWorkExperienceChange(index, "is_current", e.target.checked)}
                                                />
                                            }
                                            label="I am currently working here"
                                        />
                                        </Box>
                                      
                                    </Grid>
                                )}
                          







                                {/* </Box> */}

                                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                            Year *
                                        </InputLabel>
                                        <DatePicker
                                            selected={startYear}
                                            onChange={handleStartYearChange}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            sx={{
                                                '& .react-datepicker__input-container': {
                                                    height: '200px', // Adjust the height as needed
                                                }
                                            }}
                                        />
                                    </Grid> */}
                                <Grid item xs={12}>
                                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                                        Description
                                    </Typography>
                                    <TextField
                                        name={`background.work_experience[${index}].description`}
                                        placeholder="Description"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        value={experience.description}
                                        onChange={(e) => handleWorkExperienceChange(index, "description", e.target.value)}
                                        sx={{ mb: 2 }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    {addedWorkExperiences[index] && ( // Only show remove button for added languages
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() => handleRemoveWorkExperience(index)}
                                                variant="standard"
                                                sx={{
                                                    mb: 2, backgroundColor: 'secondary.main', color: 'text.secondary', '&:hover': {
                                                        backgroundColor: 'secondary.main'
                                                    }
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>

                            </React.Fragment>
                        ))}
                        <Grid item xs={12}>
                            <Button
                                type="button"
                                variant="standard"
                                onClick={handleAddWorkExperience}
                                sx={{
                                    backgroundColor: 'white',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'white'
                                    }
                                }}
                            >
                                <AddIcon
                                    sx={{
                                        mt: -0.5,
                                        ml: -3,
                                        backgroundColor: 'white',
                                        color: 'secondary.main',
                                        '&:hover': {
                                            backgroundColor: 'white'
                                        }
                                    }}
                                    onClick={() => navigate("/preferences")}
                                />
                                <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'secondary.main', padding: "2px" }}>
                                    Add More
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>




                <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                    Education
                </Typography>



                <Grid container spacing={2}>
                    {formik.values.background.education.map((education, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    mt={2}
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "text.primary",
                                        mb: 0.5,
                                    }}
                                >
                                    University/College *
                                </InputLabel>
                                <TextField
                                    name={`background.education[${index}].university_or_collage`}
                                    value={education.university_or_collage}
                                    onChange={(e) => handleEducationChange(index, "university_or_collage", e.target.value)}
                                    placeholder="Enter University/College"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                {formik.touched.background?.education?.[index]?.university_or_collage && formik.errors.background?.education?.[index]?.university_or_collage ? (
                                    <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                        {formik.errors.background.education[index].university_or_collage}
                                    </Typography>
                                ) : null}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    mt={2}
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "text.primary",
                                        mb: 0.5,
                                    }}
                                >
                                    Graduation Year *
                                </InputLabel>
                                <TextField
                                    name={`background.education[${index}].graduation_year`}
                                    value={education.graduation_year}
                                    onChange={(e) => handleEducationChange(index, "graduation_year", e.target.value)}
                                    placeholder="Enter Graduation Year"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                {formik.touched.background?.education?.[index]?.graduation_year && formik.errors.background?.education?.[index]?.graduation_year ? (
                                    <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                        {formik.errors.background.education[index].graduation_year}
                                    </Typography>
                                ) : null}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    mt={2}
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "text.primary",
                                        mb: 0.5,
                                    }}
                                >
                                    Field of Study *
                                </InputLabel>
                                <TextField
                                    name={`background.education[${index}].field_of_study`}
                                    value={education.field_of_study}
                                    onChange={(e) => handleEducationChange(index, "field_of_study", e.target.value)}
                                    placeholder="Enter Field of Study"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                {formik.touched.background?.education?.[index]?.field_of_study && formik.errors.background?.education?.[index]?.field_of_study ? (
                                    <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                        {formik.errors.background.education[index].field_of_study}
                                    </Typography>
                                ) : null}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    mt={2}
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "text.primary",
                                        mb: 0.5,
                                    }}
                                >
                                    Degree *
                                </InputLabel>
                                <TextField
                                    name={`background.education[${index}].degree`}
                                    value={education.degree}
                                    onChange={(e) => handleEducationChange(index, "degree", e.target.value)}
                                    placeholder="Enter Degree Name"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                {formik.touched.background?.education?.[index]?.degree && formik.errors.background?.education?.[index]?.degree ? (
                                    <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                        {formik.errors.background.education[index].degree}
                                    </Typography>
                                ) : null}
                            </Grid>

                            <Grid item xs={12}>
                                {addedEducations[index] && ( // Only show remove button for added languages
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={() => handleRemoveEducation(index)}
                                            variant="standard"
                                            sx={{
                                                mb: 2, backgroundColor: 'secondary.main', color: 'text.secondary', '&:hover': {
                                                    backgroundColor: 'secondary.main'
                                                }
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={12}>

                        <Button
                            type="button"
                            variant="standard"
                            onClick={handleAddEducation}
                            sx={{
                                backgroundColor: "white",
                                color: "white",
                                "&:hover": {
                                    backgroundColor: "white",
                                },
                            }}
                        >
                            <AddIcon
                                sx={{
                                    mt: -0.5,
                                    ml: -3,
                                    backgroundColor: "white",
                                    color: "secondary.main",
                                    "&:hover": {
                                        backgroundColor: "white",
                                    },
                                }}
                            />
                            <Typography
                                component="h2"
                                variant="body1"
                                sx={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    color: "secondary.main",
                                    padding: "2px",
                                }}
                            >
                                Add More
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            {formik.values.background.languages.map((language, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                            Language *
                                        </InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                name={`background.languages[${index}].language`}
                                                value={language.language}
                                                onChange={(e) => handleLanguageChange(index, "language", e.target.value)}
                                                onBlur={formik.handleBlur}
                                                displayEmpty
                                                renderValue={(selected) => {
                                                    if (selected === "") {
                                                        return <span style={{ color: '#aaa' }}>Select Language</span>;
                                                    }
                                                    return selected;
                                                }}
                                                error={formik.touched.background?.languages?.[index]?.language && Boolean(formik.touched.background?.languages?.[index]?.language)}
                                            >
                                                  <MenuItem value="" disabled>
                                                    <em>Select Language</em>
                                                </MenuItem>
                                                <MenuItem value="English">English</MenuItem>
                                                <MenuItem value="Spanish">Spanish</MenuItem>
                                                <MenuItem value="French">French</MenuItem>
                                                <MenuItem value="German">German</MenuItem>
                                                <MenuItem value="Italian">Italian</MenuItem>
                                                <MenuItem value="Portuguese">Portuguese</MenuItem>
                                                <MenuItem value="Mandarin Chinese">Mandarin Chinese</MenuItem>
                                                <MenuItem value="Japanese">Japanese</MenuItem>
                                                <MenuItem value="Russian">Russian</MenuItem>
                                                <MenuItem value="Arabic">Arabic</MenuItem>
                                            </Select>


                                        </FormControl>
                                        {formik.touched.background?.languages?.[index]?.language && formik.errors.background?.languages?.[index]?.language ? (
                                            <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                {formik.errors.background.languages[index].language}
                                            </Typography>
                                        ) : null}
                                        {/* <TextField
                                            placeholder="Select Language"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: false,
                                                style: {
                                                    fontSize: '14px',
                                                    color: 'gray'
                                                }
                                            }}
                                            fullWidth
                                            value={language.language}
                                            onChange={(e) => handleLanguageChange(index, "language", e.target.value)}
                                            sx={{ mb: 2 }}
                                        /> */}

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                            Level *
                                        </InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                name={`background.languages[${index}].level`}
                                                value={language.language_level}
                                                onChange={(e) => handleLanguageChange(index, "language_level", e.target.value)}
                                                onBlur={formik.handleBlur}
                                                displayEmpty
                                                renderValue={(selected) => {
                                                    if (selected === "") {
                                                        return <span style={{ color: '#aaa' }}>Select Level</span>;
                                                    }
                                                    return selected;
                                                }}
                                                error={formik.touched.background?.languages?.[index]?.language_level && Boolean(formik.touched.background?.languages?.[index]?.language_level)}
                                            >
                                               <MenuItem value="" disabled>
                                                    <em>Select Level</em>
                                                </MenuItem>
                                                <MenuItem value="Beginner">Beginner</MenuItem>
                                                <MenuItem value="Intermediate">Intermediate</MenuItem>
                                                <MenuItem value="Advanced">Advanced</MenuItem>
                                                <MenuItem value="Native">Native</MenuItem>
                                            </Select>
                                            {formik.touched.background?.languages?.[index]?.language_level && formik.errors.background?.languages?.[index]?.language_level ? (
                                                <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                    {formik.errors.background.languages[index].language_level}
                                                </Typography>
                                            ) : null}

                                        </FormControl>
                                        {/* <TextField
                                            InputLabelProps={{
                                                shrink: false,
                                                style: {
                                                    fontSize: '14px',
                                                    color: 'gray'
                                                }
                                            }}
                                            placeholder="Select Level"
                                            variant="outlined"
                                            fullWidth
                                            value={language.language_level}
                                            onChange={(e) => handleLanguageChange(index, "language_level", e.target.value)}
                                            sx={{ mb: 2 }}
                                        />
                                        {formik.touched.background?.languages?.[index]?.language_level && formik.errors.background?.languages?.[index]?.language_level ? (
                                            <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                {formik.errors.background.languages[index].language_level}
                                            </Typography>
                                        ) : null} */}
                                    </Grid>
                                    {addedLanguages[index] && (
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() => handleRemoveLanguage(index)}
                                                variant="standard"
                                                sx={{
                                                    mb: 2, backgroundColor: 'secondary.main', color: 'text.secondary', '&:hover': {
                                                        backgroundColor: 'secondary.main'
                                                    }
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ))}
                            <Grid item xs={12}>
                                <Button
                                    type="button"
                                    variant="standard"
                                    onClick={handleAddLanguage}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor: "white",
                                        },
                                    }}
                                >
                                    <AddIcon
                                        sx={{
                                            mt: -0.5,
                                            ml: -3,
                                            backgroundColor: "white",
                                            color: "secondary.main",
                                            "&:hover": {
                                                backgroundColor: "white",
                                            },
                                        }}
                                    />
                                    <Typography
                                        component="h2"
                                        variant="body1"
                                        sx={{
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            textTransform: "none",
                                            color: "secondary.main",
                                            padding: "2px",
                                        }}
                                    >
                                        Add More
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Box>

            <Box>
                <Typography
                    ml={1}
                    sx={{
                        mt: 2,
                        fontWeight: "600",
                        fontSize: "22px",
                        marginLeft: "-4px",
                        marginBottom: "15px",
                    }}
                >
                    Additional Information
                </Typography>
                <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                        alignSelf: "flex-start",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "none",
                        color: "black",
                        paddingTop: "5px",
                        mb: 0.5,
                    }}
                >
                    Objective
                </Typography>
                <TextField
                    name="background.objective"
                    value={values.background.objective}
                    onChange={handleChange}
                    placeholder="Type here..."
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    sx={{ mb: 2 }}
                />
                <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                        alignSelf: "flex-start",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "none",
                        color: "black",
                        paddingTop: "5px",
                        mb: 0.5,
                    }}
                >
                    Achievements
                </Typography>
                <TextField
                    name="background.achievements"
                    value={values.background.achievements}
                    onChange={handleChange}
                    placeholder="Type here..."
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    sx={{ mb: 2 }}
                />
                <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                        alignSelf: "flex-start",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "none",
                        color: "black",
                        paddingTop: "5px",
                        mb: 0.5,
                    }}
                >
                    Personal Characteristics
                </Typography>
                <TextField
                    name="background.personal_characteristics"
                    value={values.background.personal_characteristics}
                    onChange={handleChange}
                    placeholder="Type here..."
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    sx={{ mb: 2 }}
                />
            </Box>


        </form>
    );
};




const Background = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [formikValues, setFormikValues] = useState({

        background: {
            work_experience: [
                {
                    company_name: "",
                    job_title: "",
                    is_current: false,
                    start: dayjs(),
                    end: dayjs(),
                    description: "",
                }
            ],

            education: [
                {
                    university_or_collage: "",
                    graduation_year: "",
                    field_of_study: "",
                    degree: "",
                }
            ],

            languages: [
                {
                    language: "",
                    language_level: ""
                }
            ],
            objective: "",
            achievements: "",
            personal_characteristics: ""
        },

    });
    const validationSchema = yup.object().shape({
        background: yup.object().shape({
            work_experience: yup.array().of(
                yup.object().shape({
                    company_name: yup.string().required('Company name is required'),
                    job_title: yup.string().required('Job title is required'),
                })
            ),
            education: yup.array().of(
                yup.object().shape({
                    university_or_collage: yup.string().required('University or college is required'),
                    graduation_year: yup.string().required('Graduation year is required'),
                    field_of_study: yup.string().required('Field of study is required'),
                    degree: yup.string().required('Degree is required'),
                })
            ),
            languages: yup.array().of(
                yup.object().shape({
                    language: yup.string().required('Language is required'),
                    language_level: yup.string().required('Language level is required')
                })
            )
        })
    });


    return (
        <>

        <Box sx={{ backgroundColor: 'white', minHeight: '100vh',padding:0 }}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
              <Box sx={{mx: {lg:"150px"}}}>
      
                <Formik
                    initialValues={formikValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        submitForm(values);
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {(formik) => (
                        <Form>

                            <BackgroundForm formik={formik} />
                            <Divider sx={{ my: 2 }} />

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mt: 3,
                                }}
                            >
                                <Button
                                    type="button"
                                    onClick={() => navigate("/v1/employee/about-you")}
                                    variant="outlined"
                                    sx={{
                                        mt: 3, mb: 2, backgroundColor: 'white', borderColor: 'primary.main', color: 'text.secondary', '&:hover': {
                                            backgroundColor: 'text.secondary'
                                        }
                                    }}
                                >
                                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", }}>
                                        Back
                                    </Typography>
                                </Button>

                                <Button
                                    type="submit"
                                    variant="standard"
                                    // onClick={() => navigate("/v1/employee/background")}
                                    // onClick={() =>navigate("/v1/employee/preview-and-submit", { state: { formikValues: formik.values } })}
                                    onClick={async () => {
                                        const isValid = await formik.validateForm();
                                        if (Object.keys(isValid).length === 0) {
                                            try {
                                                await formik.submitForm();

                                                navigate("/v1/employee/preview-and-submit", {
                                                    state: { formikValues: formik.values },
                                                });
                                            } catch (error) {
                                                console.error("Error submitting form:", error);
                                            }
                                        } else {
                                            // Mark all fields as touched to display errors
                                            formik.setTouched({
                                                background: {
                                                    work_experience: [
                                                        {
                                                            company_name: true,
                                                            job_title: true,
                                                        }
                                                    ],

                                                    education: [
                                                        {
                                                            university_or_collage: true,
                                                            graduation_year: true,
                                                            field_of_study: true,
                                                            degree: true,
                                                        }
                                                    ],

                                                    languages: [
                                                        {
                                                            language: true,
                                                            language_level: true
                                                        }
                                                    ],

                                                },

                                            });
                                        }
                                    }}
                                    sx={{
                                        mt: 3, mb: 2, backgroundColor: 'secondary.main', color: 'white', '&:hover': {
                                            backgroundColor: 'secondary.main'
                                        }
                                    }}
                                >
                                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                                        Next: Background
                                    </Typography>
                                </Button>
                            </Box>

                        </Form>
                    )}
                </Formik>
          
              </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        </>
      
    );
};

export default Background;
